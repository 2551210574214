import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'reducers';
import { shallowEqual } from 'react-redux';
import { getLinkParams, imageWithSize } from 'utils/helpers';
import { ifLiveEventIsItAvailable } from 'utils/live-event';
import { TRACK_AREA, DATA_ID, DATA_VALUE } from 'utils/constants';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import { BLOCK_INTERFACE_TYPES, VIEWABLE_TYPES, BUTTON_HEIGHT } from 'utils/constants';
import ContinueWatchingButton from 'components/RemoveContinueWatchingButton/RemoveContinueWatchingButton';
import PlayButton from 'components/PlayButton/PlayButton';
import InfoButton from 'components/InfoButton/InfoButton';
import { Box } from 'components/StyledSystem/Box';
import CleanLink from 'components/StyledSystem/CleanLink';
import { useAnalytics } from 'components/Tracking';
import PreviewPlayer from 'components/PreviewPlayer';
import PlacementCalculator from './placement-calculator';
import {
  Image,
  Tooltip,
  TriggerArea,
  ImageLinkContainer,
  ANIMATION_DURATION,
  TOOLTIP_WIDTH,
  TOOLTIP_IMG_HEIGHT,
} from './Styles';
import ViewableTooltip from './ViewableTooltips/ViewableTooltip';
import { ifCatchupIsItAvailable } from 'utils/broadcast';
import { isLiveDisabled } from 'components/Player/utils';

const normalizeViewable = (viewable) => {
  if (!viewable) {
    return null;
  }

  switch (viewable.__typename) {
    case VIEWABLE_TYPES.Channel: {
      return {
        ...viewable,
        ...(viewable.schedule ? viewable.schedule[0] : {}),
        ...viewable.broadcastById || {},
      };
    }
  }

  return {
    ...viewable,
  };
};

export const getImgUrl = viewable => viewable?.schedule?.[0]?.banner || viewable?.banner;

const getImgSrc = viewable => imageWithSize(
  getImgUrl(viewable),
  TOOLTIP_WIDTH, TOOLTIP_IMG_HEIGHT,
);

const TooltipRenderer = (props) => {
  const {
    hide,
    triggerEl,
    pageGap,
    onLoad,
    viewable,
    refreshData,
    collectionId,
    categoryKind,
    ...restProps
  } = props;

  const {
    isLoggedIn,
    videoPreview,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    videoPreview: state.settings.features.videoPreview,
  }), shallowEqual);

  const analytics = useAnalytics();
  const [placement, setPlacement] = useState();
  const [bannerIsLoaded, setBannerIsLoaded] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [animation, setAnimation] = useState('');
  const [isImgVisible, setIsImgVisible] = useState(true);
  const unmounted = useRef(false);
  const tooltipRef = useRef();
  const containerRef = useRef();
  const mainViewable = viewable?.show || viewable;

  useEffect(() => {
    if (!bannerIsLoaded || unmounted.current || !tooltipRef.current) {
      return () => {};
    }

    const placementValue = new PlacementCalculator(
      triggerEl,
      tooltipRef.current,
      pageGap,
    ).findBestPlacement();

    setPlacement(placementValue);
    setAnimation(placementValue?.animOn);

    const onMouseLeave = () => {
      setAnimation(placementValue?.animOff);
      setTimeout(() => hide(), ANIMATION_DURATION);
    };

    onLoad();

    containerRef.current?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      containerRef.current?.removeEventListener('mouseleave', onMouseLeave);
      unmounted.current = true;
    };
  }, [bannerIsLoaded]);

  const isPlayAvailable = useMemo(() => {
    const normalizedViewable = normalizeViewable(viewable);
    return !!(
      normalizedViewable?.entitlement
      && ifCatchupIsItAvailable(normalizedViewable)
      && ifLiveEventIsItAvailable(normalizedViewable)
      && !isLiveDisabled(normalizedViewable)
    );
  }, [viewable]);

  const params = getLinkParams(viewable);
  const toInfo = { name: 'watch', params, query: { info: true } };
  const toPlay = isPlayAvailable ? { name: 'watch', params, query: { autoplay: true } } : toInfo;

  const isContinueWatchingCategory = categoryKind
    === BLOCK_INTERFACE_TYPES.ContinueWatchingCollection;
  const isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;

  const getExtraProps = useCallback(() => ({
    collectionId,
    categoryKind,
    viewableId: mainViewable.id,
    isPlayAvailable,
    hasTrailer: !!videoPreview
      && (!!mainViewable.trailer || (isLoggedIn && !!mainViewable.trailers?.length)),
  }), []);

  const trackClick = useCallback((area) => {
    analytics.onClick({
      component: 'HoverState',
      clickType: 'asset',
      eventName: 'click_asset',
      viewableId: mainViewable.id,
      extra: {
        ...getExtraProps(),
        area,
      },
    });
  }, []);

  const imgHandler = useCallback(() => {
    setBannerIsLoaded(true);
    setShowPlayer(true);

    analytics.onVisible({
      component: 'HoverState',
      viewableId: mainViewable.id,
      extra: getExtraProps(),
    });
  }, []);

  const onImageAnimationEnd = useCallback(() => {
    if (isImgVisible) {
      setShowPlayer(false);
    }
  }, [isImgVisible]);

  return ReactDOM.createPortal(
    <div ref={containerRef}>
      {placement?.trigger && (
        <TriggerArea
          position={placement.trigger}
          to={toInfo}
          data-id={DATA_ID.hoverStateLink}
          data-value={DATA_VALUE.triggerAreaInfo}
          onClick={() => trackClick(TRACK_AREA.triggerArea)}
        />
      )}
      <Tooltip
        data-id={DATA_ID.hoverState}
        innerRef={tooltipRef}
        position={placement?.position}
        style={placement ? { top: placement.top, left: placement.left } : { opacity: 0, pointerEvents: 'none' }}
        animation={animation}
      >
        {/* Image */}
        <ImageLinkContainer
          to={toPlay}
          data-id={DATA_ID.hoverStateLink}
          data-value={isPlayAvailable ? DATA_VALUE.imgAutoplay : DATA_VALUE.imgInfo}
          onClick={() => trackClick(TRACK_AREA.img)}
        >
          {showPlayer && !!videoPreview && (
            <PreviewPlayer
              toggleImg={setIsImgVisible}
              trailerUrl={mainViewable.trailer}
              trailers={isLoggedIn ? mainViewable.trailers : []}
              zoom={videoPreview.zoom}
              important // HS player has the highest priority
            />
          )}

          <Image
            className="videoBanner"
            onTransitionEnd={onImageAnimationEnd}
            visible={isImgVisible}
            onLoad={imgHandler}
            src={getImgSrc(viewable)}
            alt={mainViewable.title}
          />
        </ImageLinkContainer>

        {/* Buttons */}
        <Box m="large" position="absolute" height={BUTTON_HEIGHT}>
          {isPlayAvailable && (
            <PlayButton
              to={toPlay}
              data-id={DATA_ID.hoverStateLink}
              data-value={DATA_VALUE.playBtnAutoplay}
              onClick={() => trackClick(TRACK_AREA.playBtn)}
            />
          )}

          <InfoButton
            short
            to={toInfo}
            data-id={DATA_ID.hoverStateLink}
            data-value={DATA_VALUE.infoBtnInfo}
            onClick={() => trackClick(TRACK_AREA.infoBtn)}
          />

          {!isChannel && (
            <WatchlistButton
              viewable={mainViewable}
              refreshData={refreshData}
              onClick={() => trackClick(TRACK_AREA.watchlistBtn)}
              short
            />
          )}

          {isContinueWatchingCategory && (
            <ContinueWatchingButton
              viewable={viewable}
              collectionId={collectionId || ''}
              onClick={() => trackClick(TRACK_AREA.continueWatchingBtn)}
              short
            />
          )}
        </Box>

        {/* Metadata info */}
        <CleanLink
          to={toInfo}
          data-id={DATA_ID.hoverStateLink}
          data-value={DATA_VALUE.metadataInfo}
          onClick={() => trackClick(TRACK_AREA.metadata)}
        >
          <Box p="large" bg="page">
            {/* Reserve space for buttons */}
            <Box mb="large" height={BUTTON_HEIGHT} hideEmpty={false} />

            <ViewableTooltip
              viewable={viewable}
              {...restProps}
            />
          </Box>
        </CleanLink>
      </Tooltip>
    </div>,
    document.getElementById('tooltip'),
  );
};

TooltipRenderer.propTypes = {
  hide: PropTypes.func.isRequired,
  triggerEl: PropTypes.object.isRequired,
  pageGap: PropTypes.number,
  refreshData: PropTypes.func,
  categoryKind: PropTypes.string,
  collectionId: PropTypes.string,
};

export default TooltipRenderer;
