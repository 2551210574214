import React from 'react';
import PropTypes from 'prop-types';
import { viewableShape } from 'types/viewableShape';
import { useIsMediumScreen } from 'utils/hooks';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import { Box, Text, Spacer, TVODCountdown, CatchupCountdown } from 'components';
import { useSelector } from 'react-redux';
import OfferButtons from 'components/OfferButtons';
import { TrailerButton, TrailerProvider } from './Trailer';
import { renderTitle } from '../utils';
import MobileButton from './Buttons/MobileButton';
import InfoChannelLogo from './InfoChannelLogo';
import EventCounter from './EventCounter';
import PlayButton from './Buttons/PlayButton';
import { ENTITLEMENT_TYPES, VIEWABLE_TYPES } from 'utils/constants';
import { useFela } from 'react-fela';
import { useI18n } from 'components/I18n';
import EpisodeButton from './Buttons/EpisodeButton';
import { OFFER_BUTTON_AREA } from 'components/OfferButtons/OfferButton';
import ViewableMetadata from 'components/ViewableMetadata';

export const DETAILS_WIDTH = '50%';

export const tvodCountdownRules = () => ({
  fontWeight: 'bold',
  fontStyle: 'italic',
  '& .dateText': {
    fontWeight: 'normal',
  },
});

const getSeasonEpisodeTitle = (i18n, viewable) => {
  const { seasonNumber, episodeNumber, title } = viewable.viewable;
  const season = seasonNumber ? i18n.formatText('season', { seasonNumber: viewable.viewable.seasonNumber }) : undefined;
  const episode = episodeNumber ? i18n.formatText('episode', { episodeNumber: viewable.viewable.episodeNumber }) : undefined;
  const seasonEpisode = [season, episode].filter(Boolean).join(', ');
  const seasonEpisodeTitle = [seasonEpisode, title].filter(Boolean).join(' - ');
  return `${seasonEpisodeTitle}`;
};

function ViewableInfo({ viewable, refreshData, onPlay, isPlayBtnVisible, isPlayDisabled }) {
  const isMediumScreen = useIsMediumScreen();
  const isSignUpViaMovistar = useSelector(state => state.user.isSignUpViaMovistar);
  const { css } = useFela();
  const i18n = useI18n();
  const isChannelAndEpisode = viewable.__typename === VIEWABLE_TYPES.Channel && viewable.viewable?.__typename === VIEWABLE_TYPES.Episode;
  const seasonEpisodeTitle = isChannelAndEpisode ? getSeasonEpisodeTitle(i18n, viewable) : '';

  return (
    <TrailerProvider viewable={viewable}>
      <Box height="48vw" md-height="0" maxHeight="70vh" hideEmpty={false} />

      {viewable.entitlement && (
        <Box row mb="medium" justifyContent="center">
          <MobileButton />
        </Box>
      )}

      <EventCounter viewable={viewable} refreshData={refreshData} />

      <InfoChannelLogo viewable={viewable} />

      <Box mb={seasonEpisodeTitle ? 'small' : 'large'} md-width={DETAILS_WIDTH}>
        <Text
          as="h1"
          color="primary"
          fontSize="xlarge"
          xl-fontSize="xxxlarge"
          bold
          wordBreak="break-word"
          textShadow="normal"
        >
          {renderTitle(viewable)}
        </Text>
      </Box>

      {seasonEpisodeTitle && (
        <Box mb="medium" md-width={DETAILS_WIDTH}>
          <Text
            textShadow="normal"
            fontSize="medium"
            textNumberLines={1}
          >
            {seasonEpisodeTitle}
          </Text>
        </Box>
      )}

      <Box row justifyContent="start" rowGap="small" wrap>
        {isPlayBtnVisible && (
          <Box width="100%" sm-width="auto" sm-mr="small">
            <PlayButton
              onPlay={isPlayDisabled ? undefined : onPlay}
              viewable={viewable}
              disabled={isPlayDisabled}
            />
          </Box>
        )}
        {isChannelAndEpisode && (
          <Box width="100%" sm-width="auto" sm-mr="small">
            <EpisodeButton viewableId={viewable?.viewable.show.id} />
          </Box>
        )}
        {!viewable.entitlement && !isSignUpViaMovistar && (
          <OfferButtons
            isDetailView={true}
            viewable={viewable}
            dataId="details-link"
            area={OFFER_BUTTON_AREA.WATCH}
          />
        )}

        {(onPlay || (!viewable.entitlement && !isSignUpViaMovistar)) && (
          <Box width="100%" md-width="0" hideEmpty={false} />
        )}

        <Box flexBox mr="large" sm-mr="xlarge" md-mr="0" xs-fullWidth>
          <WatchlistButton
            viewable={viewable}
            refreshData={refreshData}
            short={!isMediumScreen}
            big
          />
        </Box>

        <TrailerButton short={!isMediumScreen} />
      </Box>

      {viewable.entitlement && viewable.entitlement.__typename === ENTITLEMENT_TYPES.RENT && (
        <Box mt="medium" className={css(tvodCountdownRules)}>
          <TVODCountdown
            entitlement={viewable.entitlement}
            refreshData={refreshData}
          />
        </Box>
      )}

      <Spacer height="large" xl-height="giant" />

      <Box mb="medium" md-width={DETAILS_WIDTH}>
        <Text
          textShadow="normal"
          fontSize="normal"
          color="videoMetadata"
          textNumberLines={1}
        >
          <ViewableMetadata viewable={viewable} />
          <CatchupCountdown
            viewable={viewable}
            refreshData={refreshData}
          />
        </Text>
      </Box>

      <Box md-row md-justifyContent="space-between" lineHeight="1.5em">
        <Box mb="medium" xl-mb="xxlarge" md-width={DETAILS_WIDTH}>
          <Text textShadow="normal" fontSize="normal" textNumberLines={3}>{viewable?.description}</Text>
        </Box>
      </Box>

    </TrailerProvider>
  );
}

ViewableInfo.propTypes = {
  viewable: viewableShape.isRequired,
  refreshData: PropTypes.func.isRequired,
  onPlay: PropTypes.func,
  isPlayBtnVisible: PropTypes.bool,
  isPlayDisabled: PropTypes.bool,
};

export default React.memo(ViewableInfo);
