import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, CatchupCountdown } from 'components';
import { viewableShape } from 'components/VideoItems/commonPropTypes';
import { BLOCK_INTERFACE_TYPES, VIEWABLE_TYPES } from 'utils/constants';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import ContinueWatchingButton from 'components/RemoveContinueWatchingButton/RemoveContinueWatchingButton';
import { useIsSmallScreen } from 'utils/hooks';
import createComponent from 'styles/fela/createComponent';
import { useSelector } from 'reducers';
import ViewableMetadata from 'components/ViewableMetadata';

const ButtonContainer = createComponent(({ theme, isMobile }) => ({
  flexDirection: isMobile ? 'column' : '',
  '> div': {
    display: 'flex',
    ':not(:last-child)': {
      marginBottom: isMobile ? theme.margin.fine : 0,
    },
  },
}), Box);

function ListTitles({ viewable, refreshData, categoryKind, collectionId }) {
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);
  const [maxLine, setMaxLine] = useState(4);
  const isMobile = useIsSmallScreen();
  const watchlistEnabled = useSelector(({ settings }) => !!settings.features.watchList);

  useEffect(() => {
    if (descriptionRef.current) {
      const divHeight = parseFloat(
        window.getComputedStyle(containerRef.current, null)
          .getPropertyValue('height'),
      );
      const lineHeight = parseFloat(
        window.getComputedStyle(descriptionRef.current, null)
          .getPropertyValue('line-height'),
      );

      setMaxLine(Math.floor(divHeight / lineHeight));
    }
  }, []);

  const isContinueWatchingCategory = categoryKind
    === BLOCK_INTERFACE_TYPES.ContinueWatchingCollection;
  const isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;

  return (
    <Box
      flexBox
      flexDirection="column"
      mt="small"
      sm-mt="0"
      pt="0.25rem"
      sm-ml="large"
      overflow="hidden"
      width="100%"
      xs-width="auto"
      minWidth="0px"
      flex="1 0"
      position="relative"
    >
      <Box mb={3}>
        <Text bold textNumberLines={2}>
          {
            viewable.__typename === VIEWABLE_TYPES.Episode
              ? (
                <>
                  {viewable.show?.title} <Text
                    id="seasonEpisodeShort"
                    values={{
                      seasonNumber: viewable.seasonNumber,
                      episodeNumber: viewable.episodeNumber,
                    }}
                  />
                </>
              )
              : viewable.title
          }
        </Text>
      </Box>

      <Box mb={3}>
        <Text color="secondary" fontSize="small" bold textNumberLines={1}>
          <ViewableMetadata viewable={viewable} height="small" />
          <CatchupCountdown viewable={viewable} />
        </Text>
      </Box>

      <Box innerRef={containerRef} mb="fine">
        <Text innerRef={descriptionRef} fontSize="small" textNumberLines={maxLine}>
          {viewable.description}
        </Text>
      </Box>

      {((!isChannel && watchlistEnabled) || isContinueWatchingCategory) && (
        <ButtonContainer
          flexBox
          mt="auto"
          isMobile={isMobile}
        >
          {!isChannel && watchlistEnabled && (
            <WatchlistButton
              viewable={viewable?.show || viewable}
              refreshData={refreshData}
              short={!isMobile}
            />
          )}

          {isContinueWatchingCategory && (
            <ContinueWatchingButton
              viewable={viewable}
              collectionId={collectionId || ''}
              short={!isMobile}
            />
          )}
        </ButtonContainer>
      )}
    </Box>
  );
}

ListTitles.propTypes = {
  viewable: viewableShape.isRequired,
  refreshData: PropTypes.func,
  categoryKind: PropTypes.string,
  collectionId: PropTypes.string,
};

export default React.memo(ListTitles);
