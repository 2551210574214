export const getCampaignIdProp = promoCode => `promo_${promoCode}`;

const getCampaignQueryPart = promoCode => `
  ${getCampaignIdProp(promoCode)}: campaign(promoCode: "${promoCode}") {
    usps
    name
    winback
  }
`;

export const buildGetCampaignsQuery = promoCodes => `
query getCampaigns {
  viewer {
    id: magineId
    ${promoCodes.map(getCampaignQueryPart).join('\n')}
  }
}
`;
