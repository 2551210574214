// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
import { ContentListPlayableFragmentFragmentDoc, ViewableTagFragmentFragmentDoc, ContentListEntitlementFragmentFragmentDoc } from './fragments.generated';
export type TChannelFragmentFragment = { __typename?: 'Channel', description?: string | null, logoDark?: string | null, logoLight?: string | null, schedule?: Array<{ __typename?: 'Broadcast', pgAge?: number | null, description?: string | null, title: string, start: number, stop: number, liveAvailable: boolean, banner?: string | null, catchup?: { __typename?: 'Catchup', from: number, to: number } | null }> | null, playable: { __typename?: 'BroadcastPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, watchOffset?: number | null, watched: boolean, duration?: number | null, channel?: { __typename?: 'Channel', title?: string | null, id: string, logoDark?: string | null, logoLight?: string | null } | null, catchup: { __typename?: 'Catchup', from: number, to: number } } | { __typename?: 'ChannelPlayable', id: string, kind: Types.TPlayableKind, duration?: number | null } | { __typename?: 'LiveEventPlayable', id: string, kind: Types.TPlayableKind, startTimeUtc: number, catchupStop?: any | null } | { __typename?: 'VodPlayable', id: string, kind: Types.TPlayableKind, watchOffset?: number | null, watched: boolean, duration?: number | null }, tagsToRender?: Array<{ __typename?: 'ViewableTagToRender', title?: string | null, translationKey: string, values: Array<string> }> | null, entitlement?: { __typename: 'EntitlementSubscribeType', id?: string | null } | { __typename: 'EntitlementBuyType', id?: string | null } | { __typename: 'EntitlementDefaultType', id?: string | null } | { __typename: 'EntitlementPassType', id?: string | null } | { __typename: 'EntitlementPurchaseType', id?: string | null } | { __typename: 'EntitlementRentType', id?: string | null } | { __typename: 'EntitlementThirdPartyType', id?: string | null } | null };

export const ChannelFragmentFragmentDoc = gql`
    fragment ChannelFragment on Channel {
  logoDark: image(type: "logo-dark")
  logoLight: image(type: "logo-light")
  description
  schedule: broadcastsFromLive(size: 2) {
    pgAge
    description
    banner: image(type: "sixteen-nine")
    title
    start
    stop
    liveAvailable
    catchup {
      from
      to
    }
  }
  playable {
    ...ContentListPlayableFragment
  }
  tagsToRender {
    ...ViewableTagFragment
  }
  entitlement {
    ...ContentListEntitlementFragment
  }
}
    ${ContentListPlayableFragmentFragmentDoc}
${ViewableTagFragmentFragmentDoc}
${ContentListEntitlementFragmentFragmentDoc}`;