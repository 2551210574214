import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'router/Link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  BLOCK_INTERFACE_TYPES,
  TRACK_AREA,
  DATA_ID,
  DATA_VALUE,
  VIEWABLE_TYPES,
  BREAKPOINTS,
} from 'utils/constants';
import { useIsMediumScreen, useIsMountedRef } from 'utils/hooks';
import { useAnalytics } from 'components/Tracking';
import ObservableInterval from 'utils/observable-interval';
import {
  getLiveEventState,
  extractLiveEventTime,
  ifLiveEventIsItAvailable,
} from 'utils/live-event';
import { Box, SecondaryButton, Text } from 'components';
import { viewableShape } from 'views/ContentListView/prop-types';
import PlayButton from 'views/WatchView/ViewableInfo/Buttons/PlayButton';
import OfferButtons from 'components/OfferButtons';
import WatchlistButton from 'components/WatchlistButton/WatchlistButton';
import { push } from 'router/actions';
import { useDispatch } from 'reducers';
import { OFFER_BUTTON_AREA } from 'components/OfferButtons/OfferButton';
import useResizeObserver from 'use-resize-observer';
import debounce from 'lodash.debounce';
import createComponent from 'styles/fela/createComponent';
import { maxWidth, widthRange } from 'styles/fela/mixins';

const interval = new ObservableInterval(1000);


export const Container = createComponent(({ hasOverlay }) => ({
  extend: [
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      flexWrap: 'wrap',
    }),
    {
      condition: hasOverlay,
      style: {
        extend: [
          maxWidth(BREAKPOINTS.lg, {
            flexWrap: 'wrap',
          }),
        ],
      },
    },
  ],
}), Box, ['hasOverlay']);

export default function ActionButtons({
  viewable, // Show's S1:E1 or Viewable
  collectionId,
  viewableId, // Real viewableId
  carousel,
  refreshData,
  hasOverlay,
}) {
  const {
    isLoggedIn,
    isSignUpViaMovistar,
    videoPreview,
  } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    isSignUpViaMovistar: state.user.isSignUpViaMovistar,
    videoPreview: state.settings.features.videoPreview,
  }));
  const analytics = useAnalytics();
  const isMediumScreen = useIsMediumScreen();
  const liveEventTime = extractLiveEventTime(viewable);
  const isMounted = useIsMountedRef();
  const [, setLiveEventState] = useState(null);
  const dispatch = useDispatch();
  const actionButtonRef = useRef();
  const moreInfoButtonRef = useRef();
  const [primaryButtonFullWidth, setPrimaryButtonFullWidth] = useState(false);

  const handleResize = debounce(() => {
    if (actionButtonRef.current?.offsetTop !== moreInfoButtonRef.current?.offsetTop) {
      // when more info button flow to second line, set the primary offer button to full width
      setPrimaryButtonFullWidth(true);
    }
  }, 30);
  useResizeObserver({ ref: actionButtonRef, onResize: () => {
    setPrimaryButtonFullWidth(false);
    handleResize();
  } });

  const isPlayAvailable = viewable.entitlement && ifLiveEventIsItAvailable(viewable);
  const dataId = carousel ? DATA_ID.carouselLink : DATA_ID.featuredLink;

  const correctViewable = useMemo(() => {
    const isEpisode = viewable.__typename === VIEWABLE_TYPES.Episode;
    return isEpisode && viewable.show ? viewable.show : viewable;
  }, [viewable]);

  const updateLiveEventState = () => {
    if (!isMounted.current) return;
    setLiveEventState(getLiveEventState(liveEventTime));
  };

  useEffect(() => {
    if (!liveEventTime) return;
    updateLiveEventState();

    interval.subscribe(updateLiveEventState);

    // eslint-disable-next-line consistent-return
    return () => {
      interval.unsubscribe(updateLiveEventState);
    };
  }, [liveEventTime]);

  const getExtraProps = useCallback(() => ({
    collectionId,
    categoryKind: carousel ? BLOCK_INTERFACE_TYPES.FeaturedCarouselCollection : BLOCK_INTERFACE_TYPES.FeaturedCollection,
    viewableId,
    isPlayAvailable,
    hasTrailer: !!videoPreview
      && (!!viewable.trailer || (isLoggedIn && !!viewable.trailers?.length)),
  }), []);

  const trackClick = useCallback((area) => {
    analytics.onClick({
      component: carousel ? 'carousel' : 'featured',
      clickType: 'asset',
      eventName: 'click_asset',
      viewableId: viewableId,
      extra: {
        ...getExtraProps(),
        area,
      },
    });
  }, []);

  const onClickMoreInfo = () => {
    trackClick(TRACK_AREA.infoBtn);
    dispatch(push({
      name: 'watch',
      params: {
        id: viewableId,
      },
      query: { info: true },
    }));
  };

  return (
    <Container flexBox gap="small" rowGap="small" innerRef={actionButtonRef} xs-flex={1} xs-flexDirection="column" hasOverlay={hasOverlay}>
      {!viewable.entitlement && !isSignUpViaMovistar && (
        <OfferButtons
          dataId={dataId}
          viewable={viewable}
          onClick={() => trackClick(TRACK_AREA.offerBtn)}
          area={OFFER_BUTTON_AREA.FEATURED}
          isPrimaryButtonFullWidth={primaryButtonFullWidth}
        />
      )}

      {isPlayAvailable && (
        <Box width="100%" sm-width="auto">
          <PlayButton
            data-id={dataId}
            data-value={DATA_VALUE.playBtnAutoplay}
            to={{ name: 'watch', params: { id: viewableId }, query: { autoplay: true } }}
            as={Link}
            onClick={() => trackClick(TRACK_AREA.playBtn)}
          />
        </Box>
      )}

      <Box flex={1} width="100%" sm-width="auto" innerRef={moreInfoButtonRef}>
        <SecondaryButton
          variant="white"
          minWidth="auto"
          fullWidth
          sm-fullWidth={false}
          onClick={onClickMoreInfo}
          nowrap
        >
          <Text id="featured.moreInfo" />
        </SecondaryButton>
      </Box>

      <Box flexBox mr="large" sm-mr="xlarge" md-mr="0">
        <WatchlistButton
          viewable={correctViewable}
          refreshData={refreshData}
          onClick={() => trackClick(TRACK_AREA.watchlistBtn)}
          short={!isMediumScreen}
          big
        />
      </Box>
    </Container>
  );
}

ActionButtons.propTypes = {
  viewableId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  viewable: viewableShape.isRequired,
  carousel: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
  hasOverlay: PropTypes.bool,
};
