import React from 'react';
import { Box } from 'components';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';
import { createComponent } from 'react-fela';
import { minWidth, widthRange } from 'styles/fela/mixins';
import SeasonTab from './SeasonTab';
import RelatedViewablesTab from './RelatedViewablesTab';
import LinkedCollectionViewablesTab from './LinkedCollectionViewablesTab';
import AboutTab from './AboutTab';

export const TAB_NAMES = {
  seasons: 'seasons',
  linked: 'linked',
  related: 'related',
  widgets: 'widgets',
  about: 'about',
};
export const defaultOrder = [TAB_NAMES.seasons, TAB_NAMES.linked, TAB_NAMES.related, TAB_NAMES.widgets, TAB_NAMES.about];

export function getAboutTab(viewable, i18n) {
  return (
    <Box
      key="about"
      label={i18n.formatText('watch.about')}
      py="large"
    >
      <AboutTab viewable={viewable} />
    </Box>
  );
}

const SeasonTabContainer = createComponent(({ theme }) => ({
  paddingTop: theme.margin.large,
  paddingBottom: theme.margin.large,
  marginLeft: `-${BASE_MARGIN.small}`,
  marginRight: `-${BASE_MARGIN.small}`,
  extend: [
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      marginLeft: `-${BASE_MARGIN.medium}`,
      marginRight: `-${BASE_MARGIN.medium}`,
    }),
    minWidth(BREAKPOINTS.md, {
      marginLeft: `-${BASE_MARGIN.large}`,
      marginRight: `-${BASE_MARGIN.large}`,
    }),
  ],
}), 'div');

export function getSeasonsTabs(viewable, i18n) {
  return [...(viewable?.seasons || [])]
    .sort((season1, season2) => season1.seasonNumber - season2.seasonNumber)
    .map(season => (
      <SeasonTabContainer
        key={season.seasonNumber}
        id={`season-${season.seasonNumber}`}
        label={i18n.formatText('season', { seasonNumber: season.seasonNumber })}
      >
        <SeasonTab
          viewable={viewable}
          seasonNumber={season.seasonNumber}
        />
      </SeasonTabContainer>
    ));
}

const getTitle = title => (title || '').trim();

export function getLinkedCollectionsTabs(collections = [], i18n) {
  return collections?.filter(collection => !!collection.viewables?.length)
    .map(collection => (
      <Box
        key={`linked-collection-${collection.magineId}`}
        label={getTitle(collection.title) || i18n.formatText('watch.linkedCollection')}
        py="large"
      >
        <LinkedCollectionViewablesTab
          viewables={collection.viewables}
          is16x9={collection.__typename === 'SixteenNineCollection'}
        />
      </Box>
    )) || [];
}

export function getRelatedCollectionTabs(related = [], i18n) {
  return related?.length > 0 ? [(
    <Box
      key="related"
      label={i18n.formatText('watch.relatedContent')}
      py="large"
    >
      <RelatedViewablesTab viewables={related} />
    </Box>
  )] : [];
}
