import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  FIELD_TO_RENDER_TYPES,
  LAYOUT_OBJECT_TYPES,
  VIEWABLE_TYPES,
  FIELD_TO_TAG,
} from 'utils/constants';
import { getChannelLogoData } from 'utils/channel';
import { useNotifyOnce } from 'utils/hooks';
import { formatDuration } from 'utils/human-format';
import { useI18n } from 'components/I18n';
import { Image, Text } from 'components/index';
import { RatingImg, RatingText } from 'components/ViewableMetadata/Styles';
import { getTitleAndImage } from 'views/ContentListView/Featured/utils';
import { viewableShape } from 'views/ContentListView/prop-types';
import LayoutObjectContainer from './LayoutObjectContainer';
import { collectionShape, layoutObjectShape } from './prop-types';

function Metadata({ layoutObjects, viewable, collection, lazyChannelLogo }) {
  const i18n = useI18n();
  const notify = useNotifyOnce();
  const contentRating = viewable.contentRating || {};

  const extractFieldToRender = useCallback((fieldToRender, layoutObject) => {
    let isChannel = false;
    let isProgram = false;

    if (viewable) {
      isChannel = viewable.__typename === VIEWABLE_TYPES.Channel;
      isProgram = viewable.__typename === VIEWABLE_TYPES.Program;
    }

    switch (fieldToRender) {
      case FIELD_TO_RENDER_TYPES.title: {
        return getTitleAndImage(viewable).title;
      }

      case FIELD_TO_RENDER_TYPES.synopsis: {
        return viewable.description;
      }

      case FIELD_TO_RENDER_TYPES.kind: {
        return i18n.formatText(`viewableType.${viewable.__typename}`);
      }

      case FIELD_TO_RENDER_TYPES.genres: {
        return viewable.genres?.join(', ');
      }

      case FIELD_TO_RENDER_TYPES.productionYear: {
        return viewable.productionYear;
      }

      case FIELD_TO_RENDER_TYPES.duration: {
        let duration;
        if (isChannel) {
          const broadcast = viewable.schedule?.[0];
          if (!broadcast) {
            notify(`Featured channel ${viewable.title}(${viewable.id}) does not have a schedule. Duration couldn't be established.`);
            return null;
          }

          duration = broadcast.stop - broadcast.start;
        } else if (isProgram) {
          const broadcast = viewable.defaultPlayable?.broadcast;

          if (!broadcast) {
            notify(`Featured program ${viewable.title}(${viewable.id}) does not have a schedule. Duration couldn't be established.`);
            return null;
          }

          duration = broadcast.duration || broadcast.stop - broadcast.start;

          if (!duration) {
            notify('Featured item could not determine the duration for viewable ', viewable.title);
            return null;
          }
        } else {
          duration = viewable.duration || viewable.stop - viewable.start;

          if (!duration) {
            notify('Featured item could not determine the duration for viewable ', viewable.title);
            return null;
          }
        }

        if (viewable.durationHuman) return viewable.durationHuman;

        return formatDuration(duration);
      }

      case FIELD_TO_RENDER_TYPES.rating: {
        if (contentRating.image) {
          return (
            <RatingImg
              key={contentRating.name}

              data-tooltip-id="main"
              data-tooltip-i18n={contentRating.name || viewable?.rating}

              verticalAlign="text-bottom"
              src={`${contentRating.image}?height=${layoutObject.textFontSize * 2}`} // double size because image quality
              alt={contentRating.name}
              height={layoutObject.textFontSize}
            />
          );
        }

        if (!contentRating.name && !viewable.rating) return null;

        return (
          <RatingText>{contentRating.name || viewable.rating}</RatingText>
        );
      }

      case FIELD_TO_RENDER_TYPES.directors:
      case FIELD_TO_RENDER_TYPES.cast:
      case FIELD_TO_RENDER_TYPES.customTags1:
      case FIELD_TO_RENDER_TYPES.customTags2:
      case FIELD_TO_RENDER_TYPES.customTags3:
      case FIELD_TO_RENDER_TYPES.customTags4:
      case FIELD_TO_RENDER_TYPES.customTags5:
      case FIELD_TO_RENDER_TYPES.customTags6:
      case FIELD_TO_RENDER_TYPES.customTags7:
      case FIELD_TO_RENDER_TYPES.customTags8:
      case FIELD_TO_RENDER_TYPES.customTags9:
      case FIELD_TO_RENDER_TYPES.customTags10: {
        return viewable.tagsToRender?.find(item => item.translationKey === FIELD_TO_TAG[fieldToRender])?.values.join(', ');
      }

      case FIELD_TO_RENDER_TYPES.collectionTitle: {
        return collection.title;
      }

      case FIELD_TO_RENDER_TYPES.collectionDescription: {
        return collection.description;
      }

      case FIELD_TO_RENDER_TYPES.collectionCtaLabel: {
        return collection.collectionCtaLabel;
      }

      default: {
        notify('got unexpected field to render', fieldToRender);
        return null;
      }
    }
  }, [viewable]);

  const renderLayoutObject = useCallback((layoutObject) => {
    if (layoutObject.type === LAYOUT_OBJECT_TYPES.channelLogo) {
      const channel = getChannelLogoData(viewable);
      if (!channel?.logoDark) {
        return null;
      }

      return (
        <Image
          alt={channel.title}
          src={channel.logoDark}
          height="70px"
          noPlaceholder
          lazy={lazyChannelLogo}
        />
      );
    }

    if (
      layoutObject.type === LAYOUT_OBJECT_TYPES.textString
      || layoutObject.type === LAYOUT_OBJECT_TYPES.buttonCta
    ) {
      return extractFieldToRender(layoutObject.fieldToRender, layoutObject);
    }

    if (layoutObject.type === LAYOUT_OBJECT_TYPES.textMultiFields) {
      return layoutObject.fieldsToRender
        .map(fieldToRender => extractFieldToRender(fieldToRender, layoutObject))
        .filter(value => value)
        .reduce((res, v, i) => {
          if (i) res.push(` ${layoutObject.fieldsToRenderSeparator} `);
          res.push(v);
          return res;
        }, []);
    }

    // cover a special case - genres in textKeyAndList
    if (layoutObject.type === LAYOUT_OBJECT_TYPES.textKeyAndList
      && layoutObject.fieldToRender === FIELD_TO_RENDER_TYPES.genres) {
      if (!viewable.genres?.length) {
        return null;
      }

      const title = (
        <Text
          id={FIELD_TO_TAG[layoutObject.fieldToRender]}
          values={{ valuesLength: viewable.genres.length }}
        />
      );

      return (
        <>
          {title}: {viewable.genres.join(', ')}
        </>
      );
    }

    if (layoutObject.type === LAYOUT_OBJECT_TYPES.textKeyAndList) {
      const tag = viewable.tagsToRender
        ?.find(item => item.translationKey === FIELD_TO_TAG[layoutObject.fieldToRender]);

      if (!tag) {
        return null;
      }

      const title = tag.title || (
        <Text
          id={FIELD_TO_TAG[layoutObject.fieldToRender]}
          values={{ valuesLength: tag.values.length }}
        />
      );

      return (
        <>
          {title}: {tag.values.join(', ')}
        </>
      );
    }

    return null;
  }, [viewable]);

  const items = layoutObjects.map((layoutObject, i) => (
    <LayoutObjectContainer
      key={i} // eslint-disable-line react/no-array-index-key
      layoutObject={layoutObject}
      collection={collection}
    >
      {renderLayoutObject(layoutObject)}
    </LayoutObjectContainer>
  ));

  return (
    <div suppressHydrationWarning>
      {items}
    </div>
  );
}

Metadata.propTypes = {
  collection: collectionShape,
  viewable: viewableShape,
  layoutObjects: PropTypes.arrayOf(layoutObjectShape).isRequired,
  lazyChannelLogo: PropTypes.bool,
};

export default React.memo(Metadata);
