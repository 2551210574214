import createComponent from 'styles/fela/createComponent';

export const RatingImg = createComponent(({ theme, height, verticalAlign }) => ({
  display: 'inline-block',
  height: theme.fontSize[height] || height,
  verticalAlign: verticalAlign,
}), 'img', ['height', 'verticalAlign']);

export const RatingText = createComponent(({ theme }) => ({
  display: 'inline-block',
  backgroundColor: theme.color.ratingText,
  color: theme.color.whiteBackground,
  padding: '1px 3px 0px',
  borderRadius: '2px',
  fontFamily: 'Arial, sans-serif',
  fontWeight: 'normal',
  lineHeight: '1.2em',
  wordWrap: 'normal',
  whiteSpace: 'nowrap',
}), 'div');

