
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProgramFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Program"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"genres"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"duration"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"durationHuman"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"defaultPlayable"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentListPlayableFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"tagsToRender"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewableTagFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"entitlement"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentListEntitlementFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"contentRating"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentRatingFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":372}};
    doc.loc.source = {"body":"#import \"./fragments.gql\"\n#import \"./contentRatingFragment.gql\"\n\nfragment ProgramFragment on Program {\n  genres\n  description\n  duration\n  durationHuman\n\n  defaultPlayable {\n    ...ContentListPlayableFragment\n  }\n  tagsToRender {\n    ...ViewableTagFragment\n  }\n  entitlement {\n    ...ContentListEntitlementFragment\n  }\n  contentRating {\n    ...ContentRatingFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./fragments.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./contentRatingFragment.gql").definitions));


      module.exports = doc;
    
