import {
  differenceInCalendarDays,
  parseISO,
} from 'date-fns';
import availableOffersQuery from 'queries/availableOffers.graphql';
import entitledOffersQuery from 'queries/entitledOffers.graphql';
import cancelSubscriptionQuery from 'queries/cancelSubscription.graphql';
import uncancelSubscriptionQuery from 'queries/uncancelSubscription.graphql';
import applyWinbackQuery from 'queries/applyWinbackPromoCode.graphql';
import { TApplyWinbackPromoCodeMutation } from 'queries/applyWinbackPromoCode.generated';
import {
  buildGetCampaignsQuery,
  getCampaignIdProp,
} from 'queries/getCampaigns';
import {
  buildGetWinbacksQuery, getWinbackIdProp,
} from 'queries/getWinbacks';
import {
  PURCHASE_FLOW_OFFER_TYPES,
  OFFER_TYPE_TO_QUERY_TYPE,
  EntitlementStatus,
  OfferType,
  SUBSCRIBE_KIND,
} from 'utils/constants';
import { getRecurringPeriod } from 'utils/helpers';
import { graphqlRequest, AppAsyncAction, GraphqlResult } from './helpers';
import { addMessage } from 'actions/messages';
import { DownstreamError } from 'utils/errors';

function fetchAvailableOffers(offerTypes: OfferType[]): AppAsyncAction<any> {
  return async (dispatch) => {
    const {
      data,
    } = await dispatch(graphqlRequest(availableOffersQuery, {
      type: offerTypes.map(offerType => OFFER_TYPE_TO_QUERY_TYPE[offerType]),
    }));

    return (data?.viewer?.offers?.edges || []).map((edge: any) => edge.node);
  };
}

export const FETCH_PACKS = 'packs/FETCH_PACKS';
interface FetchPacksAction {
  type: typeof FETCH_PACKS
  packs: any
  promoCode: undefined
  isPromoCodeValid: false
}
export const fetchPacks = (): AppAsyncAction => async (dispatch) => {
  const availableOffers = await dispatch(fetchAvailableOffers(PURCHASE_FLOW_OFFER_TYPES));

  dispatch<FetchPacksAction>({
    type: FETCH_PACKS,
    packs: availableOffers,
    promoCode: undefined,
    isPromoCodeValid: false,
  });
};

function extractDiscountInfo(result: any, campaignsResult: any) {
  return result.data.viewer.subscriptions.edges
    .filter(({ node }: { node: any }) => (
      node.promoCode
      && (new Date(node.promoCode.expiry).getTime() > Date.now() || !node.promoCode.expiry)
    )).map(({ node }: { node: any }) => {
      const {
        name,
        usps: bulletPoints,
        winback,
      } = campaignsResult.data.viewer[getCampaignIdProp(node.promoCode.code)];
      return ({
        discount: {
          name,
          bulletPoints,
          prefix: node.promoCode.code,
          pack: node.offerId,
          discountCycles: node.promoCode.remaining,
          winback,
        },
        endDate: new Date(node.promoCode.expiry).getTime(),
        expiry: node.promoCode.expiry,
      });
    });
}

function extractWinbackInfo(offerIds: any, winbacksResult: any) {
  return offerIds.reduce((acc: any[], offerId: string) => {
    if (winbacksResult.data.viewer[getWinbackIdProp(offerId)]) {
      return [...acc, {
        offerId: offerId,
        ...winbacksResult.data.viewer[getWinbackIdProp(offerId)],
      }];
    } else {
      return acc;
    }
  }, []);
}

/**
 * Extracts pack information from the backend result for an offers partner.
 */
function extractPacksInfo(result: any) {
  return result.data.viewer.entitlements.edges.map(({ node }: { node: any }) => {
    const subscription = result.data.viewer.subscriptions.edges.find(
      (edge: any) => edge.node.offerId === node.offer.id,
    );

    const failedRenewals = subscription?.node.failedRenewals || 0;

    let netPrice;
    let grossPrice;
    let currency;
    if (subscription?.node.billing) {
      netPrice = subscription.node.billing.price.netPrice;
      grossPrice = subscription.node.billing.price.grossPrice;
      currency = subscription.node.billing.price.currency;
    } else {
      netPrice = node.offer.priceInCents;
      grossPrice = node.offer.priceInCents;
      currency = node.offer.currency;
    }

    // FIXME Temporary: the way we calculate this value is not reliable. For
    // example, if backend has a bug where they apply the trial period again,
    // the difference between purchasedAt and trialUntil will no longer be the
    // trial period.
    // This info should be retrieved from offers.
    const trialLengthDays = Math.max(0,
      differenceInCalendarDays(parseISO(node.trialUntil), parseISO(node.purchasedAt)));

    const isOfferChangeable = subscription?.node.replacements?.edges?.some(
      (replacement: { node: { subscribeKind: string; } }) => (
        [SUBSCRIBE_KIND.UPGRADE].includes(replacement.node.subscribeKind)
      ),
    );

    return {
      __typename: node.__typename,
      id: node.offer.id,
      name: node.offer.title,
      usps: node.offer.usps || [],
      hasTrial: !!node.trialUntil,
      trialLengthDays,
      netPrice,
      grossPrice,
      isFree: grossPrice === 0,
      currency,
      recurringPeriod: getRecurringPeriod(node.offer),
      entitlementDurationSec: node.offer.entitlementDurationSec,
      nextBillingDate: node.entitledUntil
        ? new Date(node.entitledUntil)
        : null,

      // https://magine.atlassian.net/browse/MDM-8975
      status: node.status || EntitlementStatus.active,
      failedRenewals,
      endDate: node.entitledUntil ? new Date(node.entitledUntil) : null,
      trialUntil: node?.trialUntil ? new Date(node.trialUntil) : null,
      purchasedAt: node?.purchasedAt ? new Date(node.purchasedAt) : null,
      isOfferChangeable: !!isOfferChangeable,
    };
  });
}

/**
 * Fetch entitled packs and extract information for offers partners.
 *
 * The reason why the information extraction is done here, and not in the
 * reducer is because we already need to synchronize the resulting data
 * coming from offers with superscription before we get to
 * fetchEntitledOfferWithRetry.
 */
export const FETCH_ENTITLED_PACKS = 'packs/FETCH_ENTITLED_PACKS';
type EntitledPacksData = {
  packs: any[],
  discounts: any[],
};
interface FetchEntitledPacksAction {
  type: typeof FETCH_ENTITLED_PACKS
  data: EntitledPacksData,
}
export const fetchEntitledPacks = (): AppAsyncAction<EntitledPacksData> => async (dispatch) => {
  const fetchedPacks = await dispatch(graphqlRequest(entitledOffersQuery, {}));

  const promoCodes = fetchedPacks.data.viewer.subscriptions.edges
    .filter((edge: any) => edge.node.promoCode)
    .map((edge: any) => edge.node.promoCode.code);

  const campaigns = promoCodes.length ? await dispatch(
    graphqlRequest(buildGetCampaignsQuery(promoCodes), {}),
  ) : null;

  const packsData: EntitledPacksData = {
    discounts: extractDiscountInfo(fetchedPacks, campaigns),
    packs: extractPacksInfo(fetchedPacks),
  };

  dispatch<FetchEntitledPacksAction>({
    type: FETCH_ENTITLED_PACKS,
    data: packsData,
  });

  return packsData;
};

/**
 * Fetch winback for entitled packs and extract information.
 */
export const FETCH_WINBACKS = 'packs/FETCH_WINBACKS';
interface FetchWinbacksAction {
  type: typeof FETCH_WINBACKS
  winbacks: any[],
}
export const fetchWinbacks = (packsData: EntitledPacksData): AppAsyncAction => (
  async (dispatch) => {
    const offerIds = packsData.packs.map((pack: any) => pack.id);
    const winbacksData = offerIds.length ? await dispatch(
      graphqlRequest(buildGetWinbacksQuery(offerIds), {}),
    ) : null;
    const winbacks = extractWinbackInfo(offerIds, winbacksData);

    dispatch<FetchWinbacksAction>({
      type: FETCH_WINBACKS,
      winbacks,
    });
  }
);

export const cancelPackage = (packageId: string): AppAsyncAction => (
  async (dispatch) => {
    await dispatch(graphqlRequest(cancelSubscriptionQuery, { offerId: packageId }));

    await dispatch(fetchEntitledPacks());
  }
);

export const uncancelPackage = (packageId: string): AppAsyncAction => (
  async (dispatch) => {
    const {
      data,
      errors,
    } = await dispatch(graphqlRequest(uncancelSubscriptionQuery, { offerId: packageId }));

    if (!data?.uncancelSubscription) throw new Error(errors?.[0]?.message || 'Failed');

    await dispatch(fetchEntitledPacks());
  }
);

export const applyWinbackPromoCode = (campaignId: string): AppAsyncAction => (
  async (dispatch) => {
    const {
      errors,
    }: {
      data: TApplyWinbackPromoCodeMutation,
      errors: GraphqlResult['errors'],
    } = await dispatch(graphqlRequest(applyWinbackQuery, { campaignId }));

    if (errors?.length && errors[0]?.extensions?.DownstreamError) {
      const { statusCode, details } = errors[0].extensions.DownstreamError ?? {};
      throw new DownstreamError(details, statusCode);
    }

    dispatch(addMessage({
      contentId: 'subscription.winbackPromoCodeSuccess',
      type: 'success',
    }));

    await dispatch(fetchEntitledPacks());
  }
);

export type PacksActions = FetchPacksAction | FetchEntitledPacksAction | FetchWinbacksAction;
