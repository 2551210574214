import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import createComponent from 'styles/fela/createComponent';
import { Box } from 'components';

const StyledRadioButton = createComponent(({ theme, color, checked }) => ({
  border: `2px solid ${theme.color.secondary}`,
  borderRadius: '50%',
  cursor: 'pointer',
  height: theme.margin.large,
  width: theme.margin.large,
  position: 'relative',
  flex: '0 0 auto',
  backgroundColor: theme.color.whiteBackground,

  transition: 'background-color 100ms linear, border-color 100ms linear',
  extend: [
    {
      condition: checked,
      style: {
        '::before': {
          backgroundColor: theme.color[color],
          borderRadius: '50%',
          bottom: '3px',
          content: '""',
          display: 'block',
          left: '3px',
          position: 'absolute',
          right: '3px',
          top: '3px',
        },
      },
    },
  ],
}));

const RadioButton = ({ label, color = 'brand', checked, ...otherProps }) => {
  const radioButtonRef = useRef(null);

  const onClick = () => {
    radioButtonRef.current.click();
  };

  return (
    <Box flexBox alignItems="center">
      <input
        className="hidden"
        ref={radioButtonRef}
        type="radio"
        checked={checked}
        {...otherProps}
      />
      <StyledRadioButton
        onClick={onClick}
        color={color}
        checked={checked}
      />
      <Box
        display="inline-block"
        verticalAlign="middle"
        pl="small"
        cursor="pointer"
        onClick={onClick}
      >
        {label}
      </Box>
    </Box>
  );
};

RadioButton.propTypes = {
  label: PropTypes.node.isRequired,
  color: PropTypes.string,
  checked: PropTypes.bool,
};

export default RadioButton;
