// @ts-nocheck
import * as Types from '../../../@types/apiql.generated.d';

import { gql } from '@apollo/client';
export type TContentRatingFragmentFragment = { __typename?: 'ContentRating', name: string, reasons?: Array<string> | null, image?: string | null };

export const ContentRatingFragmentFragmentDoc = gql`
    fragment ContentRatingFragment on ContentRating {
  name
  reasons
  image
}
    `;