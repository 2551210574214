import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import logger from 'utils/logger';
import {
  PrimaryButton,
  Box,
  Heading,
  Text,
  Spinner,
  Panel,
  PanelWrapper,
  Metadata,
} from 'components';
import { TRANSACTION_TYPE } from 'utils/constants';
import * as routerActions from 'router/actions';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import SuccessIcon from 'components/Icons/SuccessIcon';
import { useI18n } from 'components/I18n';
import { addMessage } from 'actions/messages';
import EntitledOfferLoader from './EntitledOfferLoader';
import OfferInfo from '../CheckoutView/OfferInfo';
import * as billingActions from 'actions/billing';

const retryConfig = {
  interval: 1000,
};

function Receipt(props) {
  const dispatch = useDispatch();
  const {
    onClickNext,
    offerId,
    originalOfferId,
    createAccount,
    promoCode,
    redirectTo,
    redirectResult,
    transactionId,
  } = props;

  const {
    paymentMethod,
  } = useSelector(({ billing }) => ({
    paymentMethod: billing.paymentMethod,
  }), shallowEqual);

  const [pack, setPack] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [error, setError] = useState(null);
  const [timeout, setTimeoutValue] = useState(false);
  const timerId = useRef(null);

  const i18n = useI18n();

  const onSuccess = (offerPack, offerDiscount, transaction) => {
    props.onPaymentSucceded(offerPack, transaction);

    if (transaction.transactionType === TRANSACTION_TYPE.SUBSCRIPTION_UPGRADE) {
      // upgrade success notification
      dispatch(addMessage({
        contentId: 'changeOffer.success',
        contentValue: {
          title: offerPack.name || '',
          period: offerPack.recurringPeriod,
          price: i18n.formatCurrency(offerPack.grossPrice, offerPack.currency),
        },
        type: 'success',
      }));

      // Redirect to My plan page
      dispatch(routerActions.replace({ name: 'settings-subscription' }));
    } else {
      setPack(offerPack);
      setDiscount(offerDiscount);
    }
  };

  const onFailure = (offerError) => {
    logger.error('Fetching data from backend failed with error', offerError);
    setError(offerError);
  };

  const onRefusal = (reason) => {
    let contentId = 'payment.transactionFailed';
    let contentValue;
    if (reason) {
      const reasonI18nKey = `payment.refusal_${reason}`;
      contentId = 'payment.transactionFailedWithReason';
      contentValue = {
        reason: i18n.hasMessage(reasonI18nKey)
          ? i18n.formatText(reasonI18nKey)
          : reason,
      };
    }
    dispatch(addMessage({ contentId, contentValue }));

    if (originalOfferId) {
      dispatch(routerActions.push({
        name: 'checkout-upgrade',
        params: { id: offerId, originalOfferId },
      }));
    } else {
      dispatch(routerActions.push({
        name: 'checkout-confirmation',
        query: {
          createAccount,
          promoCode,
          packageId: offerId,
          redirectTo,
        },
      }));
    }
  };

  const onTimeout = () => {
    logger.warn('Timed out while trying to fetch entitled offer.');
    setTimeoutValue(true);
  };

  const getPaymentMethodWithRetry = () => {
    // updating of payment method is async,
    // thus we need to call get payment method api periodically to know when
    // payment method has been updated
    const timer = setTimeout(async () => {
      await dispatch(billingActions.getPaymentMethod());
      getPaymentMethodWithRetry();
    }, retryConfig.interval);
    timerId.current = timer;
  };

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!paymentMethod?.method) {
      getPaymentMethodWithRetry();
    } else {
      clearTimeout(timerId.current);
    }
  }, [paymentMethod?.method]);

  if ((!pack || !paymentMethod?.method) && !error && !timeout) {
    return (
      <PanelWrapper>
        <Panel>
          <Metadata title="payment.confirmation" />

          <Box flexBox justifyContent="center">
            <Heading
              id="payment.confirmation"
              fontWeight="bold"
              align="center"
            />
          </Box>

          <Box row my="xxlarge">
            <Spinner size={7} strokeWidth={2} />

            <EntitledOfferLoader
              offerId={offerId}
              redirectResult={redirectResult}
              transactionId={transactionId}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onTimeout={onTimeout}
              onRefusal={onRefusal}
            />
          </Box>

          <Text
            id="payment.transactionInProgress"
            display="block"
            fontSize="medium"
            align="center"
          />
        </Panel>
      </PanelWrapper>
    );
  }

  if (error) {
    return (
      <PanelWrapper>
        <ErrorPage button="retry">
          <Text id="error" />
        </ErrorPage>
      </PanelWrapper>
    );
  }

  if (timeout) {
    return (
      <PanelWrapper>
        <ErrorPage button="retry">
          <Text id="payment.transactionPendingApproval" />
        </ErrorPage>
      </PanelWrapper>
    );
  }

  const offer = {
    title: pack.name,
    currency: pack.currency,
    usps: pack.usps,
    entitlementDurationSec: pack.entitlementDurationSec,
  };

  return (
    <PanelWrapper>
      <Box
        width="100%"
        maxWidth="45rem"
        mt="xxlarge"
        mb="xlarge"
      >
        <Metadata title="receipt.success" />

        <Box mb="xlarge" textAlign="center">
          <Box mb="medium"><SuccessIcon /></Box>
          <Heading id="receipt.success" fontSize="sectionHeading" />
        </Box>

        <Box row mb="xlarge" justifyContent="center">
          <PrimaryButton variant="brand" onClick={onClickNext}>
            <Text id="receipt.nextStep" />
          </PrimaryButton>
        </Box>

        <OfferInfo
          date={pack.purchasedAt}
          usps={discount?.discount?.bulletPoints}
          offer={offer}
          price={pack.grossPrice}
          packageId={offerId}
          promoCode={discount && (promoCode || discount?.discount?.prefix)}
          trialDays={pack.trialLengthDays}
          hasDiscount={!!discount?.discount}
          reducedPrice={pack.netPrice}
          campaignName={discount?.discount?.name}
          isPromoCodeAllowed={false}
          recurringPeriod={pack.recurringPeriod}
          promoCodeExpiry={discount?.expiry}
        />
      </Box>
    </PanelWrapper>
  );
}

Receipt.propTypes = {
  offerId: PropTypes.string.isRequired,
  originalOfferId: PropTypes.string,
  createAccount: PropTypes.bool,
  promoCode: PropTypes.string,
  redirectTo: PropTypes.string,
  onPaymentSucceded: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  redirectResult: PropTypes.string,
  transactionId: PropTypes.string,
};

export default React.memo(Receipt);
