import createComponent from 'styles/fela/createComponent';
import Spacer from 'components/StyledSystem/Spacer';
import { minWidth, widthRange } from 'styles/fela/mixins';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

export const Wrapper = createComponent(() => ({
  overflow: 'hidden',
}));

export const Container = createComponent(() => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
}));

export const ItemWrapper = createComponent(() => ({
  display: 'inline-block',
  verticalAlign: 'top',
  whiteSpace: 'normal',
}));

export const StyledSpacer = createComponent(() => ({
  display: 'inline-block',
  width: BASE_MARGIN.small,
  minWidth: BASE_MARGIN.small,
  extend: [
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      width: BASE_MARGIN.medium,
      minWidth: BASE_MARGIN.medium,
    }),
    minWidth(BREAKPOINTS.md, {
      width: BASE_MARGIN.large,
      minWidth: BASE_MARGIN.large,
    }),
  ],
}), Spacer);
