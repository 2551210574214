import { VIEWABLE_TYPES } from 'utils/constants';

function extractFreeWheelAdId(content) {
  // content should look like: <Parameter name="_fw_4AID"><![CDATA[S1DOSP2012]]></Parameter>
  if (!content?.includes('_fw_4AID')) {
    return '';
  }

  const customAdIdMatcher = /CDATA\[([A-Za-z0-9]+)\]/g;
  return customAdIdMatcher.exec(content)?.[1] || '';
}

function extractAdServerAdId(content) {
  // content should look like: <AdInfo advertiserId="trailer-admin" customaid="S1EHBI1502"/>
  if (!content?.includes('customaid')) {
    return '';
  }

  const customAdIdMatcher = /customaid="([^"]+)"/g;
  return customAdIdMatcher.exec(content.replace('\\"', '"'))?.[1] || '';
}

const adIdExtractors = {
  FreeWheel: extractFreeWheelAdId,
  AdServer: extractAdServerAdId,
};
const allowedAdContentType = Object.keys(adIdExtractors);

export function extractCustomAdId(ad) {
  const { content, type } = (ad.extensions || [])
    .find(e => allowedAdContentType.includes(e?.type)) || {};

  if (!content || !type || !adIdExtractors[type]) return '';

  return adIdExtractors[type](content);
}

export function getMMSData(viewable) {
  // Channel
  if (viewable?.__typename === VIEWABLE_TYPES.Channel) {
    return {
      trackMMS: viewable?.playable.mms,
      mmsOrigCode: viewable?.playable.mmsOrigCode || '',
      assetId: viewable?.broadcastById?.broadcastId,
    };
  }
  // Show/Program/Movie with broadcast playable
  if (viewable?.defaultPlayable?.channel?.playable?.mms) {
    return {
      trackMMS: viewable.defaultPlayable.channel.playable.mms,
      mmsOrigCode: viewable.defaultPlayable.channel.playable.mmsOrigCode || '',
      assetId: viewable.episodeId || viewable.id,
    };
  }

  return {
    trackMMS: false,
    mmsOrigCode: '',
    assetId: undefined,
  };
}
