import React from 'react';
import { useSelector } from 'react-redux';
import { viewableTagShape, viewableShape } from 'types/viewableShape';
import { withSeparator } from 'utils/helpers';
import { Link, Text } from 'components';
import { RatingImg, RatingText } from 'components/ViewableMetadata/Styles';

const RATING_IMG_HEIGHT = 18;

const renderLinks = (items, isSearchEnabled) => {
  if (!isSearchEnabled) {
    return items.join(', ');
  }

  return withSeparator(items.map(
    item => (
      <Link
        key={item}
        to={{ name: 'search', query: { q: item } }}
        color="primary"
      >
        {item}
      </Link>
    ),
  ), ', ');
};

const Tag = ({ data = {} }) => {
  const {
    isSearchEnabled,
  } = useSelector(({ settings }) => ({
    isSearchEnabled: !!settings.searchMenu,
  }));

  const {
    title,
    translationKey,
    values,
    searchable,
  } = data;

  if (!values?.length) {
    return null;
  }

  return (
    <>
      <span>
        <Text bold>
          { title || (
            <Text
              id={translationKey}
              values={{ valuesLength: values.length }}
            />
          )}:
        </Text> {renderLinks(values, isSearchEnabled && searchable)}
      </span>
      <br />
    </>
  );
};

Tag.propTypes = {
  data: viewableTagShape,
};

const RatingTag = ({ viewable }) => {
  const contentRating = viewable.contentRating || {};
  const ratingText = contentRating.name || viewable?.rating;

  return (
    <>
      <span>
        <Text bold id="DetailViewMetadataRating" />: {contentRating.image ? (
          <RatingImg
            data-tooltip-id="main"
            data-tooltip-i18n={ratingText}

            height="smedium"
            verticalAlign="text-bottom"
            alt={ratingText}
            src={`${contentRating.image}?height=${RATING_IMG_HEIGHT * 2}`} // double size because image quality
          />
        ) : (
          <RatingText>{ratingText}</RatingText>
        )}
      </span>
      <br />
      {contentRating.reasons?.length && (
        <>
          {renderLinks(contentRating.reasons, false)}
          <br />
        </>
      )}
    </>
  );
};

RatingTag.propTypes = {
  viewable: viewableShape,
};

export default Tag;
export { RatingTag };
