import createComponent from 'styles/fela/createComponent';
import { minWidth, widthRange } from 'styles/fela/mixins';
import { BASE_MARGIN, BREAKPOINTS } from 'utils/constants';

export const ANIMATION_DURATION_SEC = 0.51;

export const Wrapper = createComponent(() => ({
  width: '100%',
  overflowY: 'visible',
  overflowX: 'clip',
  position: 'relative',

  '&:hover > div': {
    visibility: 'visible',
  },
}));

export const Container = createComponent(() => ({
  display: 'flex',
  transform: 'translate3d(-100%, 0, 0)',
  willChange: 'transform',
}));

export const Item = createComponent(() => ({
  width: '100%',
  flex: '0 0 100%',
}));

export const PagerContainer = createComponent(() => ({
  position: 'absolute',
  bottom: '0',
  width: '100%',
  paddingBottom: '0.7rem',
}));

export const NavigationContainer = createComponent(({ theme, type }) => ({
  visibility: 'hidden',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: '1.5rem 0',
  cursor: 'pointer',
  color: theme.color.carouselArrow,
  zIndex: 10,
  opacity: '1',
  display: 'flex',
  justifyContent: 'center',

  '& > svg': {
    transition: '0.2s ease-in-out transform',
    filter: `drop-shadow(${theme.textShadow.carouselArrow})`,
    transform: 'translateZ(0)', // handle safari
  },
  ':hover > svg': {
    transform: 'scale(1.2)',
  },

  width: BASE_MARGIN.small,
  extend: [
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      width: BASE_MARGIN.medium,
    }),
    minWidth(BREAKPOINTS.md, {
      width: BASE_MARGIN.large,
    }),
    {
      condition: type === 'left',
      style: {
        left: 0,
      },
    },
    {
      condition: type === 'right',
      style: {
        right: 0,
      },
    },
  ],
}), 'div', ['type']);
