import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box, ParentalControlModal } from 'components';
import { RadioButton } from 'components/Form';
import { RatingText } from 'components/ViewableMetadata/Styles';
import { useIsSmallScreen } from 'utils/hooks';
import { TGetParentalControlDetailsQuery } from './queries/getParentalControlDetails.generated';
import GET_PARENTAL_CONTROL_DETAILS from './queries/getParentalControlDetails.gql';
import CHANGE_RATING_LIMIT from './queries/changeRatingLimit.gql';

type RatingSystem = NonNullable<TGetParentalControlDetailsQuery['user']['ratingSystem']>;
type RatingGroup = RatingSystem['ratingGroups'][number];
type Rating = RatingGroup['ratings'][number];

type AgeLimitProps = {
  ratingSystem: RatingSystem,
};

const IMAGE_HEIGHT = 24;

const getLabels = (ratings: Rating[], isSmallScreen: boolean) => {
  return ratings.map(({ id, name, image }) => {
    if (image) {
      return (
        <img
          key={`${id}${isSmallScreen}`}
          src={`${image}?height=${IMAGE_HEIGHT * 2}`} // double size because image quality
          alt={name}
          height={`${IMAGE_HEIGHT}px`}
          {...(name ? {
            'data-tooltip-id': isSmallScreen ? 'main' : 'bottom',
            'data-tooltip-i18n': name,
          } : null)}
        />
      );
    }
    return (
      <RatingText key={id}>
        {name}
      </RatingText>
    );
  });
};

const getIds = (ratings: Rating[]) => ratings.map(({ id }) => id).filter(i => i).join(',');

const AgeLimit = ({ ratingSystem }: AgeLimitProps) => {
  const isSmallScreen = useIsSmallScreen();
  const [ratingIdModal, setRatingIdModal] = useState('');
  const { ratingGroups, id: ratingSystemId } = ratingSystem;

  const [
    changeRatingLimitMutation,
    { error, loading },
  ] = useMutation(CHANGE_RATING_LIMIT, {
    refetchQueries: [{ query: GET_PARENTAL_CONTROL_DETAILS }],
    onError() {}, // suppress promise rejection in mutation
  });

  const errorCode = error ? (error.graphQLErrors[0]?.extensions?.code || 'error') : undefined;

  useEffect(() => {
    if (!error && !loading) {
      setRatingIdModal('');
    }
  }, [error, loading]);

  const getButtons = () => {
    let enabled = false;

    return ratingGroups.map( (l, i) => {
      const last = i === ratingGroups.length - 1;
      const ids = getIds(l.ratings);

      if (l.selected) {
        enabled = true;
      }

      return (
        <Box
          position="relative"
          key={ids}
          flex={!last ? '1 1 10%' : '0 0 0%'}
          flexBox
          flexDirection="row"
          sm-maxWidth="155px"

          xs-flexDirection="column"
        >
          <Box
            flexBox
            gap="small"
            alignItems="center"
            flexDirection="column"
            width="1.5rem"
            cursor="pointer"

            xs-width="auto"
            xs-height="1.5rem"
            xs-flexDirection="row"

            onClick={() => setRatingIdModal(l.ratings[0].id)}
          >
            <RadioButton
              label=""
              checked={l.selected}
              onChange={() => setRatingIdModal(l.ratings[0].id)}
            />
            <Box
              flexBox
              flex="0 1"
              gap="2px"

              sm-width="85px"
              sm-wrap
              sm-justifyContent="center"
            >
              {getLabels(l.ratings, isSmallScreen)}
            </Box>
          </Box>
          {!last && (
            <Box
              height="2px"
              bg={enabled ? 'brand' : 'secondary'}
              mt="0.68rem"
              flex="1 1 auto"
              hideEmpty={false}

              xs-height="58px"
              xs-width="2px"
              xs-hideEmpty={false}
              xs-mt="0"
              xs-ml="0.68rem"
            />
          )}
        </Box>
      );
    });
  };

  const changeHandler = (pinCode: string) => {
    void changeRatingLimitMutation({ variables: {
      pinCode,
      ratingId: ratingIdModal,
      ratingSystemId,
    } });
  };

  const handleClose = () => {
    setRatingIdModal('');
  };

  return (
    <Box>
      <Box
        flexBox
        flexDirection="row"
        xs-flexDirection="column"
        xs-alignItems="flex-start"
      >
        {getButtons()}
      </Box>
      {!!ratingIdModal && (
        <ParentalControlModal
          descriptionTextKey="appSettings.confirmParentalControlModalInfo"
          submitPinCode={changeHandler}
          onClose={handleClose}
          pending={loading}
          error={errorCode}
        />
      )}
    </Box>
  );
};

export default React.memo(AgeLimit);
