import createComponent, { RuleProps, RuleStyles } from 'styles/fela/createComponent';
import { GlobalTheme } from 'types';
import { CSSObject } from 'fela';

export interface BoxProps extends RuleProps {
  hideEmpty?: boolean,
  m?: keyof GlobalTheme['margin'] | string | number,
  mt?: keyof GlobalTheme['margin'] | string | number,
  mr?: keyof GlobalTheme['margin'] | string | number,
  mb?: keyof GlobalTheme['margin'] | string | number,
  ml?: keyof GlobalTheme['margin'] | string | number,
  mx?: keyof GlobalTheme['margin'] | string | number,
  my?: keyof GlobalTheme['margin'] | string | number,
  p?: keyof GlobalTheme['margin'] | string | number,
  pt?: keyof GlobalTheme['margin'] | string | number,
  pr?: keyof GlobalTheme['margin'] | string | number,
  pb?: keyof GlobalTheme['margin'] | string | number,
  pl?: keyof GlobalTheme['margin'] | string | number,
  px?: keyof GlobalTheme['margin'] | string | number,
  py?: keyof GlobalTheme['margin'] | string | number,
  display?: string,
  visibility?: keyof CSSObject['visibility'],
  cursor?: string,
  hidden?: boolean,
  verticalAlign?: string,
  position?: keyof CSSObject['position'],
  relative?: string,
  zIndex?: keyof GlobalTheme['zIndex'] | keyof CSSObject['zIndex']
  top?: keyof GlobalTheme['margin'] | string | number,
  right?: keyof GlobalTheme['margin'] | string | number,
  bottom?: keyof GlobalTheme['margin'] | string | number,
  left?: keyof GlobalTheme['margin'] | string | number,
  bg?: keyof GlobalTheme['color'] | string,
  color?: keyof GlobalTheme['color'] | string,
  borderColor?: keyof GlobalTheme['color'] | string,
  transform?: string,
  transformOrigin?: string,
  flexBox?: string,
  flexDirection?: keyof CSSObject['flexDirection'],
  column?: boolean,
  row?: boolean,
  justifyContent?: string,
  alignContent?: string,
  spaceBetween?: string | number,
  alignItems?: string,
  wrap?: boolean,
  wrapReverse?: boolean,
  nowrap?: boolean,
  flex?: string,
  width?: string | number,
  fullWidth?: boolean,
  fullHeight?: boolean,
  maxWidth?: string | number,
  readableWidth?: boolean,
  height?: string | number,
  minHeight?: string | number,
  maxHeight?: string | number,
  overflow?: string,
  border?: keyof GlobalTheme['border'] | string,
  borderBottom?: keyof GlobalTheme['border'] | string,
  borderRadius?: keyof GlobalTheme['radius'] | string | number,
  textAlign?: keyof CSSObject['textAlign'],
  shadow?: string,
  minWidth?: string | number,
  gap?: string | number,
  rowGap?: string | number,
  columnGap?: string | number,
  fontSize?: string,
  float?: keyof CSSObject['float'],
  order?: number,
}

export function extractThemeProp<T = string, P = string>(
  obj: Record<string, T>,
  prop: P | undefined,
): T | P | undefined {
  if (typeof prop === 'string' && obj.hasOwnProperty(prop)) {
    return obj[prop];
  }

  return prop;
}

export const Box = createComponent((
  {
    theme,
    // if responsive, disable each mentioned size as well
    // <Box hideEmpty={false} md-m="medium" md-hideEmpty={false}>
    hideEmpty = true,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    display,
    visibility,
    cursor,
    hidden,
    verticalAlign,
    position,
    relative,
    zIndex,
    top,
    right,
    bottom,
    left,
    bg,
    transform,
    transformOrigin,
    flexBox,
    flexDirection,
    column,
    row,
    justifyContent,
    alignContent,
    spaceBetween,
    alignItems,
    wrap,
    wrapReverse,
    nowrap,
    flex,
    width,
    fullWidth,
    fullHeight,
    maxWidth,
    readableWidth,
    height,
    minHeight,
    maxHeight,
    overflow,
    border,
    borderBottom,
    borderRadius,
    textAlign,
    shadow,
    minWidth,
    gridColumn,
    fontSize,
    lineHeight,
    gap,
    rowGap,
    columnGap,
    float,
    order,
    color,
    borderColor,
  }: BoxProps,
): RuleStyles => ({
  extend: [
    {
      condition: hideEmpty,
      style: {
        nested: {
          ':empty': {
            display: 'none',
          },
        },
      },
    },
    {
      condition: typeof m !== 'undefined',
      style: {
        margin: extractThemeProp(theme.margin, m),
      },
    },
    {
      condition: typeof mt !== 'undefined',
      style: {
        marginTop: extractThemeProp(theme.margin, mt),
      },
    },
    {
      condition: typeof mr !== 'undefined',
      style: {
        marginRight: extractThemeProp(theme.margin, mr),
      },
    },
    {
      condition: typeof mb !== 'undefined',
      style: {
        marginBottom: extractThemeProp(theme.margin, mb),
      },
    },
    {
      condition: typeof ml !== 'undefined',
      style: {
        marginLeft: extractThemeProp(theme.margin, ml),
      },
    },
    {
      condition: typeof mx !== 'undefined',
      style: {
        marginLeft: extractThemeProp(theme.margin, mx),
        marginRight: extractThemeProp(theme.margin, mx),
      },
    },
    {
      condition: typeof my !== 'undefined',
      style: {
        marginTop: extractThemeProp(theme.margin, my),
        marginBottom: extractThemeProp(theme.margin, my),
      },
    },
    {
      condition: typeof p !== 'undefined',
      style: {
        padding: extractThemeProp(theme.margin, p),
      },
    },
    {
      condition: typeof pt !== 'undefined',
      style: {
        paddingTop: extractThemeProp(theme.margin, pt),
      },
    },
    {
      condition: typeof pr !== 'undefined',
      style: {
        paddingRight: extractThemeProp(theme.margin, pr),
      },
    },
    {
      condition: typeof pb !== 'undefined',
      style: {
        paddingBottom: extractThemeProp(theme.margin, pb),
      },
    },
    {
      condition: typeof pl !== 'undefined',
      style: {
        paddingLeft: extractThemeProp(theme.margin, pl),
      },
    },
    {
      condition: typeof px !== 'undefined',
      style: {
        paddingLeft: extractThemeProp(theme.margin, px),
        paddingRight: extractThemeProp(theme.margin, px),
      },
    },
    {
      condition: typeof py !== 'undefined',
      style: {
        paddingTop: extractThemeProp(theme.margin, py),
        paddingBottom: extractThemeProp(theme.margin, py),
      },
    },
    {
      condition: flexBox,
      style: {
        display: 'flex',
      },
    },
    {
      condition: visibility,
      style: {
        visibility,
      },
    },
    {
      condition: cursor,
      style: {
        cursor,
      },
    },
    {
      condition: verticalAlign,
      style: {
        verticalAlign,
      },
    },
    {
      condition: position,
      style: {
        position,
      },
    },
    {
      condition: relative,
      style: {
        position: 'relative',
      },
    },
    {
      condition: zIndex,
      style: {
        zIndex: extractThemeProp<any>(theme.zIndex, zIndex),
      },
    },
    {
      condition: top,
      style: {
        top: extractThemeProp(theme.margin, top),
      },
    },
    {
      condition: right,
      style: {
        right: extractThemeProp(theme.margin, right),
      },
    },
    {
      condition: bottom,
      style: {
        bottom: extractThemeProp(theme.margin, bottom),
      },
    },
    {
      condition: left,
      style: {
        left: extractThemeProp(theme.margin, left),
      },
    },
    {
      condition: bg,
      style: {
        backgroundColor: extractThemeProp(theme.color, bg),
      },
    },
    {
      condition: color,
      style: {
        color: extractThemeProp(theme.color, color),
      },
    },
    {
      condition: borderColor,
      style: {
        borderColor: extractThemeProp(theme.color, borderColor),
      },
    },
    {
      condition: transform,
      style: {
        transform,
      },
    },
    {
      condition: transformOrigin,
      style: {
        transformOrigin,
      },
    },
    {
      condition: flexDirection,
      style: {
        flexDirection,
      },
    },
    {
      condition: column,
      style: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    {
      condition: row,
      style: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    {
      condition: justifyContent,
      style: {
        justifyContent,
      },
    },
    {
      condition: alignContent,
      style: {
        alignContent: alignContent,
      },
    },
    {
      condition: spaceBetween,
      style: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    {
      condition: display,
      style: {
        display,
      },
    },
    {
      condition: alignItems,
      style: {
        alignItems,
      },
    },
    {
      condition: wrap,
      style: {
        flexWrap: 'wrap',
      },
    },
    {
      condition: wrapReverse,
      style: {
        flexWrap: 'wrap-reverse',
      },
    },
    {
      condition: nowrap,
      style: {
        flexWrap: 'nowrap',
      },
    },
    {
      condition: flex,
      style: {
        flex,
      },
    },
    {
      condition: width,
      style: {
        width,
      },
    },
    {
      condition: fullWidth,
      style: {
        width: '100%',
      },
    },
    {
      condition: fullHeight,
      style: {
        height: '100%',
      },
    },
    {
      condition: maxWidth,
      style: {
        maxWidth,
      },
    },
    {
      condition: readableWidth,
      style: {
        maxWidth: theme.size.readableWidth,
      },
    },
    {
      condition: height,
      style: {
        height,
      },
    },
    {
      condition: minHeight,
      style: {
        minHeight,
      },
    },
    {
      condition: maxHeight,
      style: {
        maxHeight,
      },
    },
    {
      condition: overflow,
      style: {
        overflow,
      },
    },
    {
      condition: border,
      style: {
        border: extractThemeProp(theme.border, border),
      },
    },
    {
      condition: borderBottom,
      style: {
        borderBottom: extractThemeProp(theme.border, borderBottom),
      },
    },
    {
      condition: borderRadius,
      style: {
        borderRadius: extractThemeProp(theme.radius, borderRadius),
      },
    },
    {
      condition: textAlign,
      style: {
        textAlign,
      },
    },
    {
      condition: shadow,
      style: {
        boxShadow: shadow,
      },
    },
    {
      condition: hidden,
      style: {
        display: 'none',
      },
    },
    {
      condition: minWidth,
      style: {
        minWidth,
      },
    },
    {
      condition: gridColumn,
      style: {
        gridColumn,
      },
    },
    {
      condition: lineHeight,
      style: {
        lineHeight,
      },
    },
    {
      condition: fontSize,
      style: {
        fontSize: extractThemeProp(theme.fontSize, fontSize),
      },
    },
    {
      condition: gap,
      style: {
        gap: extractThemeProp(theme.margin, gap),
      },
    },
    {
      condition: rowGap,
      style: {
        rowGap: extractThemeProp(theme.margin, rowGap),
      },
    },
    {
      condition: columnGap,
      style: {
        columnGap: extractThemeProp(theme.margin, columnGap),
      },
    },
    {
      condition: float,
      style: {
        float,
      },
    },
    {
      condition: order !== undefined,
      style: {
        order,
      },
    },
  ],
}), 'div', [
  'hideEmpty',
  'm',
  'mt',
  'mr',
  'mb',
  'ml',
  'mx',
  'my',
  'p',
  'pt',
  'pr',
  'pb',
  'pl',
  'px',
  'py',
  'display',
  'visibility',
  'cursor',
  'hidden',
  'verticalAlign',
  'position',
  'relative',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  'bg',
  'transform',
  'transformOrigin',
  'flexBox',
  'flexDirection',
  'column',
  'row',
  'justifyContent',
  'alignContent',
  'spaceBetween',
  'alignItems',
  'wrap',
  'wrapReverse',
  'nowrap',
  'flex',
  'width',
  'fullWidth',
  'fullHeight',
  'maxWidth',
  'readableWidth',
  'height',
  'minHeight',
  'maxHeight',
  'overflow',
  'border',
  'borderBottom',
  'borderRadius',
  'textAlign',
  'shadow',
  'minWidth',
  'gridColumn',
  'fontSize',
  'lineHeight',
  'gap',
  'rowGap',
  'columnGap',
  'float',
  'order',
  'color',
  'borderColor',
], true);

Box.displayName = 'Box';
