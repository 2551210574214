import createComponent from 'styles/fela/createComponent';
import {
  responsiveSm,
  responsiveMd,
  withBackgroundResizer,
  withOverlay,
  widthRange,
  minWidth,
} from 'styles/fela/mixins';
import { VIDEO_ANIMATION_DURATION } from 'components/PreviewPlayer';
import { BASE_MARGIN, BREAKPOINTS, COLLECTION_SIZE } from 'utils/constants';

export const HeroContainer = createComponent(({ imageSize }) => ({
  position: 'relative',

  maxHeight: [COLLECTION_SIZE.large, COLLECTION_SIZE.sixteenNine].includes(imageSize) ? '89vh' : '64vh',
  // set height smaller than 100% 16/9 height so that metadata will be within the background image
  height: imageSize !== COLLECTION_SIZE.sixteenNine
    ? '55.125vw' // 98vw / (16 / 9)
    : '45vw', // 80vw / (16 / 9)
  minHeight: '33.65rem',
  width: '100%',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  overflow: 'visible',
  zIndex: 0,
}), 'div', ['imageSize']);

export const Container = createComponent(({ imageSize }) => ({
  width: '100%',
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  left: 0,

  minHeight: '100%',
  maxHeight: COLLECTION_SIZE.large === imageSize ? '89vh' : '64vh',
  height: '55.125vw', // 98vw / (16 / 9)
}), 'div', ['imageSize']);

export const Container16x9 = createComponent(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  aspectRatio: theme.size.image16x9,
}), 'div', ['imageSize']);

export const ContainerMax = createComponent(({ theme, height }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  minHeight: '33.65rem', // HeroContainer minHeight
  maxHeight: '120vh',

  extend: [
    responsiveSm({
      minHeight: '33.65rem',
    }),
    responsiveMd({
      '& #hp-subtitles': {
        display: 'block',
      },
    }),
    {
      condition: height,
      style: {
        '& .muteBtnContainer': {
          // align position with Watch button
          top: `calc(${height}px - 4rem - 3.5rem)`,
          bottom: 'auto',
          right: BASE_MARGIN.small,
          extend: [
            widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
              right: BASE_MARGIN.medium,
            }),
            minWidth(BREAKPOINTS.md, {
              right: BASE_MARGIN.large,
            }),
          ],

          '& svg': {
            width: '3.5rem',
            height: '3.5rem',
          },
        },
        '& .restartBtnContainer': {
          top: `calc(${height}px - 4rem - 3.5rem)`,
        },
        '& #hp-subtitles': {
          display: 'none',
          // align position with Watch button
          bottom: `calc(100% - ${height}px + 4rem)`,
          marginRight: '3.5rem', // mute button
          marginLeft: theme.margin.giant,
          left: '45vw', // metadata container offset
          width: 'auto',
          fontSize: theme.fontSize.large,

          extend: [
            widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
              right: BASE_MARGIN.medium, // page margin
            }),
            minWidth(BREAKPOINTS.md, {
              right: BASE_MARGIN.large, // page margin
            }),
          ],
        },
      },
    },
  ],
}), 'div', ['imageSize', 'height']);

export const Background = createComponent(({ src, imagePosition = 'center', width, height, visible }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,

  opacity: visible ? 1 : 0,
  transition: `opacity ${VIDEO_ANIMATION_DURATION}ms ease-in`,

  backgroundImage: src ? `url(${src})` : 'none',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: `50% ${imagePosition}`,
  extend: [
    withBackgroundResizer({ src: src || 'none', width, height }),
  ],
}), 'div', ['src', 'imagePosition', 'width', 'height', 'visible']);

export const Overlay = createComponent(({ theme, overlay }) => ({
  position: 'absolute',
  left: '0',
  top: '0',
  bottom: '0',
  right: '0',
  zIndex: 1,
  extend: [
    withOverlay(overlay || theme.color.overlayDarkFaint, -1),
  ],
}), 'div', ['overlay']);

export const Gradient = createComponent(({ background }) => ({
  width: '100%',
  height: '100%',
  background: background || 'none',
}), 'div', []);

export const RestartBtnContainer = createComponent(() => ({
  position: 'absolute',
  zIndex: 2,
  right: BASE_MARGIN.small,
  extend: [
    widthRange(BREAKPOINTS.sm, BREAKPOINTS.md, {
      right: BASE_MARGIN.medium,
    }),
    minWidth(BREAKPOINTS.md, {
      right: BASE_MARGIN.large,
    }),
  ],
}));
