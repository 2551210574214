import React, { useEffect, useMemo } from 'react';
import { colorWithOpacity } from 'utils/helpers';
import { VIEWABLE_TYPES, COLLECTION_SIZE } from 'utils/constants';
import { extractLiveEventTime } from 'utils/live-event';
import {
  Hero,
  Box,
  LiveLabel,
  LiveInfo,
  Spacer,
} from 'components';
import Metadata from 'components/CollectionUI/Metadata';
import { UpcomingTitle, TITLE_TYPES } from 'components/Upcoming';
import { extractUpcomingStartTime } from 'utils/upcoming';
import { useCollectionUIStyles } from 'utils/hooks';
import { CollectionUIGradientType, CollectionUIType, GlobalTheme, ViewableType } from 'types';
import { useViewableLazy } from 'views/WatchView/useViewable';
import { useTheme } from 'server/theme/hooks';
import {
  Content,
  CollectionContainer,
  ActionButtonsContainer,
} from 'views/ContentListView/Featured/Styles';
import { getTitleAndImage, getProperViewable } from './utils';
import MetadataContainer from './MetadataContainer';
import ActionButtons from './ActionButtons';

type FeaturedProps = {
  collectionId: string,
  viewable: ViewableType,
  collectionUI?: CollectionUIType,
  active?: boolean,
  carousel?: boolean,
  onTrailerEnd?: () => void,
  lazyChannelLogo: boolean,
  refreshData: () => void,
};

const addGradient = (
  direction: string,
  { color, opacity, length }: CollectionUIGradientType,
  backgroundArr: string[],
) => {
  const colorCode = colorWithOpacity(color, opacity);
  backgroundArr.push(`linear-gradient(${direction}, ${colorCode}, transparent ${length}%)`);
};

const getGradientBackground = (
  props: {
    theme: GlobalTheme,
    collectionSize?: string,
    gradientTop?: CollectionUIGradientType, gradientRight?: CollectionUIGradientType,
    gradientBottom?: CollectionUIGradientType, gradientLeft?: CollectionUIGradientType,
  },
) => {
  const { theme, collectionSize, gradientTop, gradientRight, gradientBottom, gradientLeft } = props;
  const background: string[] = [];

  if (gradientTop) {
    addGradient('to bottom', gradientTop, background);
  }

  if (gradientRight) {
    addGradient('to left', gradientRight, background);
  }

  if (collectionSize === COLLECTION_SIZE.sixteenNine) {
    addGradient('to top', { color: theme.color.page, opacity: 100, length: 50 }, background);
  } else if (gradientBottom) {
    addGradient('to top', gradientBottom, background);
  }

  if (gradientLeft) {
    addGradient('to right', gradientLeft, background);
  }

  return background.join(',');
};

function Featured(props: FeaturedProps): JSX.Element {
  const {
    collectionId,
    viewable,
    collectionUI,
    active,
    onTrailerEnd,
    lazyChannelLogo = true,
    carousel = false,
    refreshData,
  } = props;

  const theme = useTheme();

  const [loadFullViewable, { data }] = useViewableLazy({
    variables: {
      viewableId: viewable.id,
      broadcastId: '',
    },
  });

  const collectionUIStyles = useCollectionUIStyles(collectionUI);
  const layoutObjects = collectionUIStyles?.layoutObjects || [];

  const collectionOverlay = collectionUI ? colorWithOpacity(
    collectionUI.collectionLayoverColor,
    collectionUI.collectionLayoverOpacity,
  ) : undefined;

  const metadataOverlay = collectionUI?.metadataContainerOpacity ? colorWithOpacity(
    collectionUI.metadataContainerColor,
    collectionUI.metadataContainerOpacity,
  ) : '';

  const showBlur = Boolean(collectionUI?.metadataContainerOpacity
    && collectionUI?.metadataContainerOpacity > 0);
  const { image } = getTitleAndImage(viewable);
  const liveEventTime = extractLiveEventTime(viewable);
  const upcomingTime = extractUpcomingStartTime(viewable);

  const {
    collectionSize,
    collectionGradientTop, collectionGradientRight,
    collectionGradientBottom, collectionGradientLeft,
  } = collectionUI || {};

  const gradientBackground = useMemo(() => getGradientBackground({
    theme,
    collectionSize,
    gradientTop: collectionGradientTop,
    gradientRight: collectionGradientRight,
    gradientBottom: collectionGradientBottom,
    gradientLeft: collectionGradientLeft,
  }), [
    collectionSize,
    collectionGradientTop, collectionGradientRight,
    collectionGradientBottom, collectionGradientLeft,
  ]);

  useEffect(() => {
    if (viewable?.__typename === VIEWABLE_TYPES.Show) {
      // we need Episodes to show Action Buttons
      loadFullViewable();
    }
  }, [viewable]);

  return (
    <Hero
      viewable={viewable}
      src={image}
      active={active}
      onTrailerEnd={onTrailerEnd}
      imageSize={collectionSize}
      imagePosition={collectionUI?.heroImageVerticalAlignment}
      overlay={collectionOverlay}
      gradientBackground={gradientBackground}
      className="e2e-featured"
      enableTrailer={!!collectionUI?.videoPreview}
    >
      <Content column alignItems="flex-start" fullWidth>
        {
          upcomingTime ? (
            <Box
              mb="small"
              ml="xlarge"
              zIndex="1"
              relative
            >
              <UpcomingTitle
                type={TITLE_TYPES.HERO}
                startTime={upcomingTime}
                color="white"
                textShadow="hero"
                fontSize="sectionHeading"
              />
            </Box>
          ) : null
        }

        <MetadataContainer
          showBlur={showBlur}
          overlay={metadataOverlay}
          withLiveLabel={!!liveEventTime}
          layoutObjects={layoutObjects}
          viewableId={viewable.id}
        >
          {liveEventTime && (
            <Box
              position="absolute"
              bottom="100%"
              left={metadataOverlay ? '5px' : undefined}
              row
            >
              <LiveLabel
                big
                liveEventTime={liveEventTime}
              />

              <Spacer width="large" />

              <LiveInfo
                fromHero
                liveEventTime={liveEventTime}
                onlyToday
              />
            </Box>
          )}

          {collectionUI ? (
            <CollectionContainer
              flex="1 1 auto"
              minHeight="0"
              overflow="hidden"
            >
              <Metadata
                viewable={viewable}
                layoutObjects={layoutObjects}
                lazyChannelLogo={lazyChannelLogo}
              />
            </CollectionContainer>
          ) : (
            <Spacer />
          )}

          <ActionButtonsContainer>
            {(data || viewable?.__typename !== VIEWABLE_TYPES.Show) && (
              <ActionButtons
                viewableId={viewable.id}
                collectionId={collectionId}
                viewable={getProperViewable(data || viewable)}
                carousel={carousel}
                refreshData={refreshData}
                hasOverlay={!!metadataOverlay}
              />
            )}
          </ActionButtonsContainer>
        </MetadataContainer>
      </Content>
    </Hero>
  );
}

export default React.memo(Featured);
