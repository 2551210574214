/* eslint-disable consistent-return */
import { logout, getCheckoutFirstRoute } from 'actions/auth';
import * as packsActions from 'actions/packs';
import * as commonActions from 'actions/common';
import logger from 'utils/logger';
import { PLATFORMS } from 'utils/constants';

export function createRedirectLink({ name, params = {}, query = {} }) {
  const target = {
    ...(name ? { name } : {}),
    ...(Object.keys(params).length ? { params } : {}),
    ...(Object.keys(query).length ? { query } : {}),
  };

  return Object.keys(target).length ? JSON.stringify(target) : undefined;
}

export function requireLoginStrict(store, { location }) {
  if (!store.getState().auth.isLoggedIn) {
    return {
      name: 'log-in',
      params: {
        platform: location.params?.platform,
      },
      query: {
        redirectTo: createRedirectLink(location.name === 'home' ? { ...location, name: undefined } : location),
      },
    };
  }
}

export function requireLogin(store, { location }) {
  const state = store.getState();

  if (state.auth.isLoggedIn || state.country.isOpenServiceActive) {
    return;
  }

  // Open service is inactive, redirect to login
  return {
    name: 'log-in',
    query: {
      redirectTo: createRedirectLink(location.name === 'home' ? { ...location, name: undefined } : location),
    },
  };
}

/**
 * Remember last location by saving it in a query parameter `redirectTo`
 */
export function rememberLastLocation(store, { location, previousLocation, previousRoute }) {
  if (previousLocation.query.redirectTo) { // carry on redirect link
    return {
      ...location,
      query: {
        ...location.query,
        redirectTo: previousLocation.query.redirectTo,
      },
    };
  }

  if (
    !previousRoute.couldBeInitialView // do not create redirectTo param for couldBeInitialView route
    || previousRoute.name === 'home' // skip 'home' cause anyway we redirect to 'home' by default
    || location.query?.redirectTo // don't create redirectTo if following location already have it
  ) {
    return;
  }

  return {
    ...location,
    query: {
      ...location.query,
      redirectTo: createRedirectLink(previousLocation),
    },
  };
}

/**
 * Remember promo code by saving it in a query parameter `promoCode`
 */
export function rememberPromoCode(store, { location, previousLocation }) {
  if (!store.getState().settings.features.payment?.allowPromoCodes) {
    return;
  }

  const locationsToIgnore = ['checkout', 'checkout-confirmation', 'checkout-receipt'];

  if (locationsToIgnore.includes(previousLocation?.name)) {
    return;
  }

  const promoCode = previousLocation?.query.promoCode;

  if (promoCode) {
    return { // add promoCode query param to the current location
      ...location,
      query: {
        ...location.query,
        promoCode,
      },
    };
  }
}

/**
 * Remember offer ID by saving it in a query parameter `packageId`
 */
export function rememberPackageId(store, { location, previousLocation }) {
  const locationsToIgnore = ['checkout', 'checkout-confirmation', 'checkout-receipt'];

  if (locationsToIgnore.includes(previousLocation?.name)) {
    return;
  }

  const packageId = previousLocation?.query.packageId;

  if (packageId) {
    return {
      ...location,
      query: {
        ...location.query,
        packageId,
      },
    };
  }
}

export async function requirePayment(store, { location }) {
  try {
    const state = store.getState();

    // we don't care about anonymous users here
    if (!state.auth.isLoggedIn) {
      return;
    }

    // check if it is mandatory to have entitlement after registration
    if (!state.settings.features.payment?.onboarding?.mandatory) {
      return;
    }

    // check if user has any entitlements
    if (!store.getState().packs.entitled.length) {
      // check again if user has any entitlements in case fetch entitlement was not called before
      await store.dispatch(packsActions.fetchEntitledPacks());
      if (!store.getState().packs.entitled.length) {
        // go to checkout if no entitlement
        return store.dispatch(getCheckoutFirstRoute(location.query));
      }
    }
  } catch (err) {
    logger.error('REQUIRE_PAYMENT_SUBSCRIPTION_ERROR', err);
    throw err;
  }
}

export function requireLogout(store) {
  if (store.getState().auth.isLoggedIn) {
    return { name: 'home' };
  }
}

export function requireCreateAccountEnabled(store) {
  const { country } = store.getState();
  if (!country.isCreateAccountEnabled) {
    return { name: 'home' };
  }
}

export async function doLogout(store) {
  try {
    await store.dispatch(logout(__CLIENT__));

    return {
      name: 'log-in',
      reload: true,
    };
  } catch (e) {
    logger.error('LOGOUT_ERROR', e);
    throw e;
  }
}

export function redirectWatchContext(store, { location }) {
  const { id, playableId } = location.params;
  return {
    name: 'watch',
    params: {
      id,
      playableId,
    },
  };
}

export function validatePlatform(_, { location }) {
  const validPlatforms = [
    PLATFORMS.VIZIO,
    PLATFORMS.WEBOS,
    PLATFORMS.TIZEN,
    PLATFORMS.BROWSER,
    PLATFORMS.MOVISTAR,
    PLATFORMS.IOS,
    PLATFORMS.ANDROID,
  ];
  if (!validPlatforms.includes(location.params?.platform?.toLowerCase())) {
    return { name: 'create-account' };
  }
}

export function requireLoginForAddingToWatchlist(store, { location }) {
  const state = store.getState();

  if (state.auth.isLoggedIn || !('addToWatchlist' in location.query)) {
    return;
  }

  return {
    name: 'log-in',
    query: {
      redirectTo: createRedirectLink(location),
    },
  };
}

export function redirectPlatform(store, { location }) {
  const platform = location.params?.platform?.toLowerCase();
  const { common } = store.getState();
  if (platform && location.query?.redirectTo && !common.platformLogin) {
    store.dispatch(commonActions.updatePlatformInfo({
      ...common,
      platformLogin: {
        location,
      },
    }));
  } else if (!platform && common.platformLogin) {
    return common.platformLogin.location;
  }
}

export function requireNotSignUpViaMovistar(store) {
  if (store.getState().user.isSignUpViaMovistar) {
    return {
      name: 'settings-payment',
    };
  }
}
