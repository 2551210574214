import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reducers';
import * as userActions from 'actions/user';
import { RequestStates } from 'utils/helpers';
import { ClickTracker } from 'components/Tracking';
import {
  Box,
  Text,
  Heading,
  SecondaryButton,
  Hr,
  Metadata,
} from 'components';
import { I18nContext } from 'components/I18n';
import SettingsContainer from '../SettingsContainer';
import ParentalControlSection from './ParentalControlSection';

const MAX_WIDTH = '31rem';

const AppSettingsView = () => {
  const [resetDevices, setResetDevices] = React.useState({
    state: RequestStates.initial,
    messageId: 'appSettings.resetDevicesPreMessage',
  });
  const dispatch = useDispatch<AppDispatch>();

  const {
    parentalControlEnabled,
    deviceLimit,
    screenLimit,
  } = useSelector(({ settings }: RootState) => ({
    parentalControlEnabled: !!settings.features.parentalControl,
    deviceLimit: settings.features.settings?.deviceLimit || 0,
    screenLimit: settings.features.settings?.screenLimit || 0,
  }), shallowEqual);

  const i18n = React.useContext(I18nContext);
  const additionalSectionEnabled = !!i18n.hasMessage('appSettings.additionalSectionMessage');
  const additionalSectionSecondEnabled = !!i18n.hasMessage('appSettings.additionalSectionSecondMessage');

  const resetLimit = async () => {
    setResetDevices({
      state: RequestStates.pending,
      messageId: '',
    });

    try {
      await dispatch(userActions.resetDevices());
      setResetDevices({
        state: RequestStates.success,
        messageId: 'appSettings.resetDevicesSuccess',
      });
    } catch (e: any) {
      if (e.res?.body?.error) {
        setResetDevices({
          state: RequestStates.failed,
          messageId: `appSettings.resetDevicesFailure.${e.res.body.error?.message}`,
        });
      } else {
        setResetDevices({
          state: RequestStates.failed,
          messageId: 'appSettings.resetDevicesFailure',
        });
      }
    }
  };

  return (
    <SettingsContainer wide>
      <Metadata title="settings" />

      <Heading
        id="settings"
        letterSpacing="2.3px"
        mb="large"
      />

      <Heading
        id="appSettings.deviceLimitHeading"
        fontSize="sectionHeading"
        mb="medium"
      />

      <Box mb="large" maxWidth={MAX_WIDTH}>
        <Text
          id="subscription.packDeviceLimit"
          values={{
            deviceCount: (
              <Text
                key="deviceCount"
                id="subscription.deviceCount"
                values={{ deviceLimit }}
                bold
                fontSize="small"
              />
            ),
            screenCount: (
              <Text
                key="screenCount"
                id="subscription.screenCount"
                values={{ screenLimit }}
                bold
                fontSize="small"
              />
            ),
          }}
          color="secondary"
          fontSize="small"
        />
      </Box>

      {deviceLimit > 0 && (
        <Box maxWidth={MAX_WIDTH}>
          <Box mb="large">
            <Hr />
          </Box>

          <Box mb="medium">
            <ClickTracker
              component="Settings"
              element="clear_device_list"
              clickType="action"
            >
              <SecondaryButton
                variant="white"
                onClick={resetLimit}
                showSpinner={resetDevices.state === RequestStates.pending}
              >
                <Text id="appSettings.resetDevicesBtn" />
              </SecondaryButton>
            </ClickTracker>
          </Box>
          <Box mb="xlarge">
            <Text
              id={resetDevices.messageId}
              color={resetDevices.state === RequestStates.failed ? 'danger' : 'secondary'}
              fontSize="small"
            />
          </Box>
        </Box>
      )}

      {additionalSectionEnabled && (
        <Box maxWidth={MAX_WIDTH}>
          <Box mb="xlarge">
            <Hr />
          </Box>

          <Heading
            id="appSettings.additionalSectionHeading"
            fontSize="sectionHeading"
            mb="medium"
          />

          <Box mb="xlarge">
            <Text
              id="appSettings.additionalSectionMessage"
              whiteSpace="pre-wrap"
              color="secondary"
              fontSize="small"
            />
          </Box>
        </Box>
      )}

      {additionalSectionSecondEnabled && (
        <Box maxWidth={MAX_WIDTH}>
          <Box mb="xlarge">
            <Hr />
          </Box>

          <Heading
            id="appSettings.additionalSectionSecondHeading"
            fontSize="sectionHeading"
            mb="medium"
          />

          <Box mb="xlarge">
            <Text
              id="appSettings.additionalSectionSecondMessage"
              whiteSpace="pre-wrap"
              color="secondary"
              fontSize="small"
            />
          </Box>
        </Box>
      )}

      {parentalControlEnabled && (
        <Box>
          <Box mb="xlarge" maxWidth={MAX_WIDTH}>
            <Hr />
          </Box>

          <ParentalControlSection />
        </Box>
      )}
    </SettingsContainer>
  );
};

export default React.memo(AppSettingsView);
