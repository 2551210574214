import React from 'react';
import logger from 'utils/logger';
import Text from 'components/StyledSystem/Text';
import Spacer from 'components/StyledSystem/Spacer';
import { ViewableType } from 'types';
import {
  Container,
  IconContainer,
  StyledCircleIcon,
  StyledPlusIcon,
} from './Styles';
import { useRemoveFromContinueWatchingMutation } from './queries/removeContinueWatching.generated';
import { removeFromContinueWatchingList } from './queries/actions';

type RemoveContinueWatchingButtonProps = {
  viewable: ViewableType,
  collectionId: string,
  short?: boolean,
  onClick: () => void,
};

function RemoveContinueWatchingButton(props: RemoveContinueWatchingButtonProps) {
  const { viewable, collectionId, short, onClick, ...restProps } = props;
  const [
    removeFromContinueWatching,
    removeFromContinueWatchingResult,
  ] = useRemoveFromContinueWatchingMutation({
    update(cache) {
      removeFromContinueWatchingList(cache, viewable, collectionId);
    },
  });
  const inProgress = removeFromContinueWatchingResult?.loading;

  const remove = async (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (inProgress) {
      return;
    }

    onClick?.();

    try {
      await removeFromContinueWatching({ variables: { viewableId: viewable.id } });
    } catch (e) {
      logger.error('Remove continue watching failed:', e);
    }
  };

  return (
    <Container
      onClick={remove}
      inProgress={inProgress}
      short={short}
      {...restProps}
    >
      <IconContainer
        data-tooltip-id="main"
        data-tooltip-i18n="continueWatching.remove"
      >
        <StyledCircleIcon />
        <StyledPlusIcon
          inProgress={inProgress}
        />
      </IconContainer>

      {!short && (
        <>
          <Spacer width="medium" />
          <Text
            fontSize="small"
            id="continueWatching.remove"
          />
        </>
      )}
    </Container>
  );
}

export default React.memo(RemoveContinueWatchingButton);
